import React, { useState } from 'react';
import { Plus, Pencil, Trash2, Save, X } from 'lucide-react';
import { useCategories } from '../../hooks/useCategories';
import { Category } from '../../data/types';

const CategoryManagement = () => {
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);
  const [newCategory, setNewCategory] = useState('');
  const { categories, addCategory, updateCategory, deleteCategory } = useCategories();

  const handleAddCategory = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newCategory.trim()) return;

    try {
      await addCategory?.mutateAsync(newCategory);
      setNewCategory('');
    } catch (error) {
      console.error('Error adding category:', error);
      alert('Failed to add category. Please try again.');
    }
  };

  const handleUpdateCategory = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editingCategory || !editingCategory.name.trim()) return;

    try {
      await updateCategory?.mutateAsync({
        id: editingCategory.id,
        name: editingCategory.name
      });
      setEditingCategory(null);
    } catch (error) {
      console.error('Error updating category:', error);
      alert('Failed to update category. Please try again.');
    }
  };

  const handleDeleteCategory = async (id: number) => {
    if (!window.confirm('Are you sure you want to delete this category?')) return;

    try {
      await deleteCategory?.mutateAsync(id);
    } catch (error) {
      console.error('Error deleting category:', error);
      alert('Failed to delete category. Please try again.');
    }
  };

  if (categories.isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      <form onSubmit={handleAddCategory} className="flex gap-4">
        <input
          type="text"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder="New category name"
          className="flex-1 bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <button
          type="submit"
          disabled={!newCategory.trim() || addCategory?.isPending}
          className="bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded-lg transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <Plus className="h-5 w-5" />
          Add Category
        </button>
      </form>

      <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-xl overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b border-white/10">
                <th className="text-left p-4">Category Name</th>
                <th className="text-left p-4">Products</th>
                <th className="text-left p-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {categories.data?.map((category) => (
                <tr key={category.id} className="border-b border-white/10">
                  <td className="p-4">
                    {editingCategory?.id === category.id ? (
                      <form onSubmit={handleUpdateCategory} className="flex gap-2">
                        <input
                          type="text"
                          value={editingCategory.name}
                          onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
                          className="flex-1 bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                        <button
                          type="submit"
                          className="p-2 hover:bg-green-500/20 rounded-lg transition-colors text-green-500"
                        >
                          <Save className="h-5 w-5" />
                        </button>
                        <button
                          type="button"
                          onClick={() => setEditingCategory(null)}
                          className="p-2 hover:bg-red-500/20 rounded-lg transition-colors text-red-500"
                        >
                          <X className="h-5 w-5" />
                        </button>
                      </form>
                    ) : (
                      <span>{category.name}</span>
                    )}
                  </td>
                  <td className="p-4">
                    <span className="text-gray-400">{category.product_count?.toString() || '0'} products</span>
                  </td>
                  <td className="p-4">
                    <div className="flex gap-2">
                      <button
                        onClick={() => setEditingCategory(category)}
                        className="p-2 hover:bg-blue-500/20 rounded-lg transition-colors text-blue-500"
                      >
                        <Pencil className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleDeleteCategory(category.id)}
                        className="p-2 hover:bg-red-500/20 rounded-lg transition-colors text-red-500"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CategoryManagement;