import { useQuery } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';

export const useAdminDashboard = () => {
  const getStats = async () => {
    const { data, error } = await supabase
      .rpc('get_admin_dashboard_stats');

    if (error) throw error;
    return data;
  };

  const getRecentOrders = async () => {
    const { data, error } = await supabase
      .from('orders')
      .select(`
        *,
        items:order_items(
          quantity,
          product:products(name)
        )
      `)
      .order('created_at', { ascending: false })
      .limit(5);

    if (error) throw error;
    return data;
  };

  const getRecentTradeIns = async () => {
    const { data, error } = await supabase
      .from('trade_in_requests')
      .select('*')
      .order('created_at', { ascending: false })
      .limit(5);

    if (error) throw error;
    return data;
  };

  const getRecentRepairs = async () => {
    const { data, error } = await supabase
      .from('repair_requests')
      .select('*')
      .order('created_at', { ascending: false })
      .limit(5);

    if (error) throw error;
    return data;
  };

  const getRecentHosting = async () => {
    const { data, error } = await supabase
      .from('hosting_requests')
      .select('*')
      .order('created_at', { ascending: false })
      .limit(5);

    if (error) throw error;
    return data;
  };

  return {
    stats: useQuery({
      queryKey: ['admin-stats'],
      queryFn: getStats,
      refetchInterval: 30000 // Refresh every 30 seconds
    }),
    recentOrders: useQuery({
      queryKey: ['admin-recent-orders'],
      queryFn: getRecentOrders,
      refetchInterval: 30000
    }),
    recentTradeIns: useQuery({
      queryKey: ['admin-recent-trade-ins'],
      queryFn: getRecentTradeIns,
      refetchInterval: 30000
    }),
    recentRepairs: useQuery({
      queryKey: ['admin-recent-repairs'],
      queryFn: getRecentRepairs,
      refetchInterval: 30000
    }),
    recentHosting: useQuery({
      queryKey: ['admin-recent-hosting'],
      queryFn: getRecentHosting,
      refetchInterval: 30000
    })
  };
};