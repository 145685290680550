import { supabase } from '../lib/supabase';
import { STORAGE_BUCKETS } from '../lib/supabase';

export const useSupabaseStorage = () => {
  const uploadProductImage = async (file: File, productId: number): Promise<string> => {
    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `${productId}-${Date.now()}.${fileExt}`;

      const { error: uploadError, data } = await supabase.storage
        .from(STORAGE_BUCKETS.PRODUCTS)
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: true
        });

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from(STORAGE_BUCKETS.PRODUCTS)
        .getPublicUrl(fileName);

      return publicUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const deleteProductImage = async (imageUrl: string) => {
    try {
      // Extract filename from URL
      const url = new URL(imageUrl);
      const fileName = url.pathname.split('/').pop();
      if (!fileName) return;

      const { error } = await supabase.storage
        .from(STORAGE_BUCKETS.PRODUCTS)
        .remove([fileName]);

      if (error) throw error;
    } catch (error) {
      console.error('Error deleting image:', error);
      throw error;
    }
  };

  return {
    uploadProductImage,
    deleteProductImage
  };
};