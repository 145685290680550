import React, { useState, useEffect } from 'react';
import { Save, AlertTriangle } from 'lucide-react';
import { useSettings } from '../../hooks/useSettings';
import { SiteSettings } from '../../data/types';

const SettingsManagement = () => {
  const { settings, updateSettings } = useSettings();
  const [formData, setFormData] = useState<SiteSettings>({
    site_name: '',
    site_url: '',
    support_email: '',
    default_currency: 'EUR',
    vat_rate: 21,
    shipping_methods: {
      standard: {
        name: 'Standard Shipping',
        price: 135,
        estimatedDays: '5-7'
      },
      ddp: {
        name: 'DDP Shipping',
        percentage: 18,
        estimatedDays: '3-5'
      }
    }
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (settings.data) {
      setFormData(settings.data);
    }
  }, [settings.data]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      await updateSettings.mutateAsync(formData);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update settings');
    }
  };

  if (settings.isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-xl p-6">
        <h2 className="text-xl font-bold mb-6">Site Settings</h2>

        {error && (
          <div className="bg-red-500/10 border border-red-500/50 rounded-lg p-4 mb-6 flex items-start gap-3">
            <AlertTriangle className="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5" />
            <p className="text-red-400">{error}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium mb-2">Site Name</label>
              <input
                type="text"
                value={formData.site_name}
                onChange={(e) => setFormData({ ...formData, site_name: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Site URL</label>
              <input
                type="url"
                value={formData.site_url}
                onChange={(e) => setFormData({ ...formData, site_url: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium mb-2">Support Email</label>
              <input
                type="email"
                value={formData.support_email}
                onChange={(e) => setFormData({ ...formData, support_email: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Default Currency</label>
              <select
                value={formData.default_currency}
                onChange={(e) => setFormData({ ...formData, default_currency: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="EUR">EUR (€)</option>
                <option value="USD">USD ($)</option>
                <option value="GBP">GBP (£)</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">VAT Rate (%)</label>
            <input
              type="number"
              min="0"
              max="100"
              value={formData.vat_rate}
              onChange={(e) => setFormData({ ...formData, vat_rate: parseInt(e.target.value) })}
              className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required
            />
          </div>

          <div className="border-t border-white/10 pt-6">
            <h3 className="text-lg font-semibold mb-4">Standard Shipping</h3>
            <div className="grid md:grid-cols-3 gap-6">
              <div>
                <label className="block text-sm font-medium mb-2">Name</label>
                <input
                  type="text"
                  value={formData.shipping_methods.standard.name}
                  onChange={(e) => setFormData({
                    ...formData,
                    shipping_methods: {
                      ...formData.shipping_methods,
                      standard: {
                        ...formData.shipping_methods.standard,
                        name: e.target.value
                      }
                    }
                  })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Price (€)</label>
                <input
                  type="number"
                  min="0"
                  value={formData.shipping_methods.standard.price}
                  onChange={(e) => setFormData({
                    ...formData,
                    shipping_methods: {
                      ...formData.shipping_methods,
                      standard: {
                        ...formData.shipping_methods.standard,
                        price: parseFloat(e.target.value)
                      }
                    }
                  })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Estimated Days</label>
                <input
                  type="text"
                  value={formData.shipping_methods.standard.estimatedDays}
                  onChange={(e) => setFormData({
                    ...formData,
                    shipping_methods: {
                      ...formData.shipping_methods,
                      standard: {
                        ...formData.shipping_methods.standard,
                        estimatedDays: e.target.value
                      }
                    }
                  })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>
            </div>
          </div>

          <div className="border-t border-white/10 pt-6">
            <h3 className="text-lg font-semibold mb-4">DDP Shipping</h3>
            <div className="grid md:grid-cols-3 gap-6">
              <div>
                <label className="block text-sm font-medium mb-2">Name</label>
                <input
                  type="text"
                  value={formData.shipping_methods.ddp.name}
                  onChange={(e) => setFormData({
                    ...formData,
                    shipping_methods: {
                      ...formData.shipping_methods,
                      ddp: {
                        ...formData.shipping_methods.ddp,
                        name: e.target.value
                      }
                    }
                  })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Percentage (%)</label>
                <input
                  type="number"
                  min="0"
                  max="100"
                  value={formData.shipping_methods.ddp.percentage}
                  onChange={(e) => setFormData({
                    ...formData,
                    shipping_methods: {
                      ...formData.shipping_methods,
                      ddp: {
                        ...formData.shipping_methods.ddp,
                        percentage: parseFloat(e.target.value)
                      }
                    }
                  })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Estimated Days</label>
                <input
                  type="text"
                  value={formData.shipping_methods.ddp.estimatedDays}
                  onChange={(e) => setFormData({
                    ...formData,
                    shipping_methods: {
                      ...formData.shipping_methods,
                      ddp: {
                        ...formData.shipping_methods.ddp,
                        estimatedDays: e.target.value
                      }
                    }
                  })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            disabled={updateSettings.isPending}
            className="bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {updateSettings.isPending ? (
              <>
                <div className="animate-spin rounded-full h-5 w-5 border-2 border-white/20 border-t-white"></div>
                <span>Saving...</span>
              </>
            ) : (
              <>
                <Save className="h-5 w-5" />
                <span>Save Settings</span>
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SettingsManagement;