import { useQuery } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';
import { Product } from '../data/types';

export const useProducts = (categoryId?: number) => {
  return useQuery({
    queryKey: ['products', categoryId],
    queryFn: async () => {
      let query = supabase
        .from('products')
        .select(`
          *,
          category:categories(id, name),
          manufacturer:manufacturers(*)
        `);
      
      if (categoryId) {
        query = query.eq('category_id', categoryId);
      }
      
      const { data, error } = await query;
      
      if (error) {
        throw error;
      }
      
      return data.map(product => ({
        ...product,
        category: product.category.name,
        manufacturer: product.manufacturer
      })) as Product[];
    }
  });
};