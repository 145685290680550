import { supabase } from './supabase';
import { useCartStore } from '../stores/cartStore';

export const createOrder = async (data: {
  customerInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address: string;
    city: string;
    country: string;
    postalCode: string;
    notes?: string;
  };
  paymentMethod: 'crypto' | 'bank';
  transactionId?: string;
  items: any[];
  total: number;
}) => {
  const cart = useCartStore.getState();
  const user = (await supabase.auth.getUser()).data.user;

  // Generate order number
  const orderNumber = `ORD-${Date.now().toString(36).toUpperCase()}`;

  // Determine shipping method based on cart items
  const hasDDP = data.items.some(item => item.shippingOption === 'ddp');
  const shippingMethod = hasDDP ? 'DDP' : 'Standard';

  // Create order with pending payment status
  const { data: order, error: orderError } = await supabase
    .from('orders')
    .insert({
      order_number: orderNumber,
      user_id: user?.id,
      customer_name: `${data.customerInfo.firstName} ${data.customerInfo.lastName}`,
      customer_email: data.customerInfo.email,
      customer_phone: data.customerInfo.phone,
      customer_address: `${data.customerInfo.address}, ${data.customerInfo.city}, ${data.customerInfo.postalCode}, ${data.customerInfo.country}`,
      payment_method: data.paymentMethod,
      payment_status: 'pending',
      shipping_method: shippingMethod,
      shipping_status: 'awaiting_payment',
      subtotal: cart.getSubtotal(),
      shipping_cost: cart.getShippingTotal() + cart.getDDPTotal(),
      total_amount: data.total,
      transaction_id: data.transactionId,
      notes: data.customerInfo.notes
    })
    .select()
    .single();

  if (orderError) throw orderError;

  // Create order items
  const orderItems = data.items.map(item => ({
    order_id: order.id,
    product_id: item.id,
    quantity: item.quantity,
    price_per_unit: item.price,
    shipping_option: item.shippingOption,
    shipping_cost: item.shippingOption === 'standard' ? 135 : item.price * 0.18,
    total_amount: item.price * item.quantity
  }));

  const { error: itemsError } = await supabase
    .from('order_items')
    .insert(orderItems);

  if (itemsError) throw itemsError;

  // Send notification email to admin
  try {
    await supabase.functions.invoke('send-order-notification', {
      body: {
        orderNumber,
        customerName: `${data.customerInfo.firstName} ${data.customerInfo.lastName}`,
        customerEmail: data.customerInfo.email,
        customerPhone: data.customerInfo.phone,
        paymentMethod: data.paymentMethod,
        totalAmount: data.total
      }
    });
  } catch (error) {
    console.error('Failed to send admin notification:', error);
  }

  return order;
};