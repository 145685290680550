export interface MinerModel {
  name: string;
  power: number;
  hashrate: number;
  algorithm: string;
  unit: string;
}

export const hostingModels: Record<string, MinerModel> = {
  // Latest Bitcoin Miners
  's21_pro_234th': {
    name: 'S21 Pro (234Th)',
    power: 7450,
    hashrate: 234,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  },
  's21_200th': {
    name: 'S21 (200Th)',
    power: 6200,
    hashrate: 200,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  },
  's21_plus_216th': {
    name: 'S21+ (216Th)',
    power: 6480,
    hashrate: 216,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  },
  
  // Hydro-cooling Models
  's21_hydro_335th': {
    name: 'S21 Hydro (335Th)',
    power: 8710,
    hashrate: 335,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  },
  's21xp_hyd_473th': {
    name: 'S21XP Hydro (473Th)',
    power: 11825,
    hashrate: 473,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  },
  's21exp_hyd_860th': {
    name: 'S21EXP Hydro (860Th)',
    power: 21500,
    hashrate: 860,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  },
  
  // Litecoin Miners
  'l9_16g': {
    name: 'L9 (16GH)',
    power: 3200,
    hashrate: 16,
    algorithm: 'Scrypt',
    unit: 'GH/s'
  },
  'l9_pro_18g': {
    name: 'L9 Pro (18GH)',
    power: 3400,
    hashrate: 18,
    algorithm: 'Scrypt',
    unit: 'GH/s'
  },
  'l7_9500m': {
    name: 'L7 (9.5GH)',
    power: 3425,
    hashrate: 9.5,
    algorithm: 'Scrypt',
    unit: 'GH/s'
  },
  'l7s_8800m': {
    name: 'L7S (8.8GH)',
    power: 3425,
    hashrate: 8.8,
    algorithm: 'Scrypt',
    unit: 'GH/s'
  },
  'l7_pro_12g': {
    name: 'L7 Pro (12GH)',
    power: 3500,
    hashrate: 12,
    algorithm: 'Scrypt',
    unit: 'GH/s'
  },
  
  // Alephium Miners
  'al3_15th': {
    name: 'AL3 (15TH)',
    power: 2400,
    hashrate: 15,
    algorithm: 'Blake3',
    unit: 'TH/s'
  },
  'al3_pro_18th': {
    name: 'AL3 Pro (18TH)',
    power: 2600,
    hashrate: 18,
    algorithm: 'Blake3',
    unit: 'TH/s'
  },
  'al1m_4_4th': {
    name: 'AL1M (4.4TH)',
    power: 1000,
    hashrate: 4.4,
    algorithm: 'Blake3',
    unit: 'TH/s'
  },
  'al2_lite_2th': {
    name: 'AL2 Lite (2TH)',
    power: 600,
    hashrate: 2,
    algorithm: 'Blake3',
    unit: 'TH/s'
  },
  
  // High Efficiency Models
  's19e_xp_240th': {
    name: 'S19E XP (240Th)',
    power: 6480,
    hashrate: 240,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  },
  's19e_xp_251th': {
    name: 'S19E XP (251Th)',
    power: 6776,
    hashrate: 251,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  },
  
  // Pro Series
  's19_pro_hyd_198th': {
    name: 'S19 Pro+ Hydro (198Th)',
    power: 5445,
    hashrate: 198,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  },
  's19_pro_hyd_191th': {
    name: 'S19 Pro+ Hydro (191Th)',
    power: 5252,
    hashrate: 191,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  },
  
  // Whatsminer Models
  'm50s_plus_136th': {
    name: 'M50S+ (136Th)',
    power: 3400,
    hashrate: 136,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  },
  'm50s_pro_150th': {
    name: 'M50S Pro (150Th)',
    power: 3600,
    hashrate: 150,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  },
  
  // Avalon Models
  'a1366_110th': {
    name: 'Avalon 1366 (110Th)',
    power: 3300,
    hashrate: 110,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  },
  'a1366_pro_140th': {
    name: 'Avalon 1366 Pro (140Th)',
    power: 3500,
    hashrate: 140,
    algorithm: 'SHA-256',
    unit: 'TH/s'
  }
};