import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Upload, X, Plus, CircuitBoard, Cpu, Battery, AlertTriangle, Package, Truck, Server } from 'lucide-react';

interface RepairRequestFormProps {
  onSubmit: (data: any) => void;
  onClose: () => void;
}

const RepairRequestForm = ({ onSubmit, onClose }: RepairRequestFormProps) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    deviceType: '',
    model: '',
    serialNumber: '',
    issueDescription: '',
    photos: [] as string[],
    shippingAddress: '',
    billingAddress: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    agreeToTerms: false
  });

  const minerModels = {
    'Antminer S19': ['Complete Machine', 'Hashboard', 'Control Board', 'Power Supply'],
    'Antminer S19 Pro': ['Complete Machine', 'Hashboard', 'Control Board', 'Power Supply'],
    'Antminer S19j Pro': ['Complete Machine', 'Hashboard', 'Control Board', 'Power Supply'],
    'Antminer T19': ['Complete Machine', 'Hashboard', 'Control Board', 'Power Supply'],
    'Whatsminer M30S': ['Complete Machine', 'Hashboard', 'Control Board', 'Power Supply'],
    'Whatsminer M30S+': ['Complete Machine', 'Hashboard', 'Control Board', 'Power Supply']
  };

  const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newPhotos = Array.from(e.target.files).map(file => URL.createObjectURL(file));
      setFormData({ ...formData, photos: [...formData.photos, ...newPhotos] });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const getPartIcon = (part: string) => {
    switch (part) {
      case 'Complete Machine':
        return <Server className="h-5 w-5" />;
      case 'Hashboard':
        return <CircuitBoard className="h-5 w-5" />;
      case 'Control Board':
        return <Cpu className="h-5 w-5" />;
      case 'Power Supply':
        return <Battery className="h-5 w-5" />;
      default:
        return null;
    }
  };

  const renderStep = () => {
    switch(step) {
      case 1:
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-bold mb-4">Select Your Device</h3>
            
            <div>
              <label className="block text-sm font-medium mb-2">Miner Model</label>
              <select
                value={formData.model}
                onChange={(e) => setFormData({ ...formData, model: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              >
                <option value="">Select a model</option>
                {Object.keys(minerModels).map((model) => (
                  <option key={model} value={model}>{model}</option>
                ))}
              </select>
            </div>

            {formData.model && (
              <div>
                <label className="block text-sm font-medium mb-2">Part Type</label>
                <div className="grid grid-cols-2 gap-4">
                  {minerModels[formData.model as keyof typeof minerModels].map((part) => (
                    <button
                      key={part}
                      type="button"
                      onClick={() => setFormData({ ...formData, deviceType: part })}
                      className={`p-4 rounded-lg border ${
                        formData.deviceType === part
                          ? 'border-blue-500 bg-blue-500/20'
                          : 'border-white/10 hover:border-blue-500/50'
                      } transition-colors flex flex-col items-center gap-2`}
                    >
                      {getPartIcon(part)}
                      <span className="text-sm">{part}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div>
              <label className="block text-sm font-medium mb-2">Serial Number</label>
              <input
                type="text"
                value={formData.serialNumber}
                onChange={(e) => setFormData({ ...formData, serialNumber: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Issue Description</label>
              <textarea
                value={formData.issueDescription}
                onChange={(e) => setFormData({ ...formData, issueDescription: e.target.value })}
                rows={4}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Photos</label>
              <div className="grid grid-cols-2 gap-4">
                {formData.photos.map((photo, index) => (
                  <div key={index} className="relative rounded-lg overflow-hidden">
                    <img src={photo} alt={`Upload ${index + 1}`} className="w-full h-32 object-cover" />
                    <button
                      type="button"
                      onClick={() => setFormData({
                        ...formData,
                        photos: formData.photos.filter((_, i) => i !== index)
                      })}
                      className="absolute top-2 right-2 bg-black/60 p-1 rounded-full hover:bg-black/80 transition-colors"
                    >
                      <X className="h-4 w-4" />
                    </button>
                  </div>
                ))}
                <label className="border-2 border-dashed border-white/10 rounded-lg p-4 hover:border-blue-500/50 transition-colors cursor-pointer flex flex-col items-center justify-center">
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handlePhotoUpload}
                    className="hidden"
                  />
                  <Plus className="h-8 w-8 mb-2" />
                  <span className="text-sm text-gray-400">Add Photos</span>
                </label>
              </div>
            </div>

            <button
              type="button"
              onClick={() => setStep(2)}
              disabled={!formData.model || !formData.deviceType || !formData.serialNumber}
              className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Continue to Shipping Details
            </button>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-bold mb-4">Shipping Instructions</h3>

            <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-xl p-6 space-y-4">
              <div className="flex items-start gap-3">
                <Package className="h-5 w-5 text-blue-500 mt-1" />
                <div>
                  <h4 className="font-semibold">Packing Requirements</h4>
                  <ul className="list-disc list-inside text-gray-400 text-sm mt-2">
                    <li>Use strong cardboard box with adequate padding</li>
                    <li>Wrap item in anti-static bubble wrap</li>
                    <li>Secure all loose components</li>
                    <li>Include copy of repair request form</li>
                  </ul>
                </div>
              </div>

              <div className="flex items-start gap-3">
                <Truck className="h-5 w-5 text-blue-500 mt-1" />
                <div>
                  <h4 className="font-semibold">Shipping Address</h4>
                  <p className="text-gray-400 text-sm mt-2">
                    Euronet Trading Co. Ltd.<br />
                    Repair Department<br />
                    275 New North Road<br />
                    Islington<br />
                    London, N1 7AA<br />
                    United Kingdom
                  </p>
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Return Shipping Address</label>
              <textarea
                value={formData.shippingAddress}
                onChange={(e) => setFormData({ ...formData, shippingAddress: e.target.value })}
                rows={4}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Billing Address</label>
              <textarea
                value={formData.billingAddress}
                onChange={(e) => setFormData({ ...formData, billingAddress: e.target.value })}
                rows={4}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <button
              type="button"
              onClick={() => setStep(3)}
              disabled={!formData.shippingAddress || !formData.billingAddress}
              className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Continue to Contact Details
            </button>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-bold mb-4">Contact Information</h3>

            <div>
              <label className="block text-sm font-medium mb-2">Contact Name</label>
              <input
                type="text"
                value={formData.contactName}
                onChange={(e) => setFormData({ ...formData, contactName: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Email</label>
              <input
                type="email"
                value={formData.contactEmail}
                onChange={(e) => setFormData({ ...formData, contactEmail: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Phone</label>
              <input
                type="tel"
                value={formData.contactPhone}
                onChange={(e) => setFormData({ ...formData, contactPhone: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div className="bg-yellow-500/10 border border-yellow-500/50 rounded-lg p-4">
              <div className="flex items-start gap-3">
                <AlertTriangle className="h-5 w-5 text-yellow-500 flex-shrink-0 mt-1" />
                <div>
                  <h4 className="font-semibold text-yellow-500">Important Notice</h4>
                  <ul className="list-disc list-inside text-sm mt-2">
                    <li>€25 diagnostic fee applies to all repair requests</li>
                    <li>Fee is non-refundable regardless of repair acceptance</li>
                    <li>Shipping costs are the responsibility of the client</li>
                    <li>Final repair cost will be quoted after diagnostics</li>
                  </ul>
                </div>
              </div>
            </div>

            <label className="flex items-start gap-3">
              <input
                type="checkbox"
                checked={formData.agreeToTerms}
                onChange={(e) => setFormData({ ...formData, agreeToTerms: e.target.checked })}
                className="mt-1 rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                required
              />
              <span className="text-sm text-gray-400">
                I understand and agree to the diagnostic fee, shipping terms, and repair conditions
              </span>
            </label>

            <button
              type="submit"
              onClick={handleSubmit}
              disabled={!formData.agreeToTerms || !formData.contactEmail || !formData.contactPhone}
              className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
            >
              <Upload className="h-5 w-5" />
              <span>Submit Repair Request</span>
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-gradient-to-b from-blue-900/40 to-black/40 rounded-2xl max-w-2xl w-full max-h-[90vh] overflow-y-auto border border-white/10"
      >
        <div className="sticky top-0 bg-black/90 backdrop-blur-sm p-6 border-b border-white/10 flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold">New Repair Request</h2>
            <div className="flex mt-2">
              {[1, 2, 3].map((stepNumber) => (
                <div
                  key={stepNumber}
                  className={`w-16 h-1 rounded-full mr-2 ${
                    stepNumber <= step ? 'bg-blue-500' : 'bg-gray-700'
                  }`}
                />
              ))}
            </div>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-lg transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          {renderStep()}
        </form>
      </motion.div>
    </div>
  );
};

export default RepairRequestForm;