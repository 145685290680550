import React from 'react';
import { motion } from 'framer-motion';
import { Scale, Shield, FileText } from 'lucide-react';

const TermsPage = () => {
  return (
    <div className="pt-24 pb-16">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div className="text-center mb-16">
          <FileText className="h-12 w-12 text-blue-500 mx-auto mb-4" />
          <h1 className="text-4xl font-bold mb-4">Terms of Service</h1>
          <p className="text-xl text-gray-400">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>

        <div className="prose prose-invert prose-blue max-w-none">
          <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-2xl p-8 mb-8">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Scale className="h-6 w-6 mr-2 text-blue-500" />
              1. Agreement to Terms
            </h2>
            <p className="text-gray-400">
              By accessing and using the services provided by Euronet Trading Co. Ltd. ("we," "our," or "us"), 
              including our website at euronet-trading.co.uk, you agree to be bound by these Terms of Service. 
              If you do not agree to these terms, please do not use our services.
            </p>
          </div>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">2. Trade-In Services</h2>
            <div className="space-y-4 text-gray-400">
              <p>
                Our trade-in program allows customers to exchange their existing cryptocurrency mining hardware 
                for store credit. The following conditions apply:
              </p>
              <ul className="list-disc list-inside space-y-2">
                <li>All trade-in values are provided as store credit only</li>
                <li>Store credit can only be used for purchasing new equipment from us</li>
                <li>Final trade-in value is subject to verification of equipment condition</li>
                <li>We reserve the right to reject any trade-in that doesn't meet our criteria</li>
              </ul>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">3. Product Sales</h2>
            <div className="space-y-4 text-gray-400">
              <p>
                All products sold through our platform are subject to the following terms:
              </p>
              <ul className="list-disc list-inside space-y-2">
                <li>Prices are subject to change without notice</li>
                <li>Product availability is not guaranteed until order confirmation</li>
                <li>Shipping times are estimates and may vary</li>
                <li>Import duties and taxes are the responsibility of the buyer</li>
              </ul>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">4. Warranty and Returns</h2>
            <div className="space-y-4 text-gray-400">
              <p>
                Our warranty and return policy includes:
              </p>
              <ul className="list-disc list-inside space-y-2">
                <li>Standard manufacturer warranty on all new equipment</li>
                <li>14-day return policy for unused, unopened items</li>
                <li>Shipping costs for returns are buyer's responsibility</li>
                <li>Refunds are processed within 10 business days</li>
              </ul>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">5. User Accounts</h2>
            <div className="space-y-4 text-gray-400">
              <p>
                When creating an account, you agree to:
              </p>
              <ul className="list-disc list-inside space-y-2">
                <li>Provide accurate and complete information</li>
                <li>Maintain the security of your account credentials</li>
                <li>Accept responsibility for all activities under your account</li>
                <li>Notify us immediately of any unauthorized access</li>
              </ul>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">6. Limitation of Liability</h2>
            <div className="space-y-4 text-gray-400">
              <p>
                Euronet Trading Co. Ltd. shall not be liable for:
              </p>
              <ul className="list-disc list-inside space-y-2">
                <li>Any indirect, incidental, or consequential damages</li>
                <li>Loss of profits or revenue</li>
                <li>Hardware failures or mining performance issues</li>
                <li>Delays or disruptions in service</li>
              </ul>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">7. Intellectual Property</h2>
            <div className="space-y-4 text-gray-400">
              <p>
                All content on our website, including but not limited to text, graphics, logos, and software, 
                is the property of Euronet Trading Co. Ltd. and is protected by intellectual property laws.
              </p>
            </div>
          </section>

          <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-2xl p-8 mt-12">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Shield className="h-6 w-6 mr-2 text-blue-500" />
              Contact Information
            </h2>
            <p className="text-gray-400 mb-4">
              For any questions about these Terms of Service, please contact us at:
            </p>
            <ul className="text-gray-400 space-y-2">
              <li>Email: legal@euronet-trading.co.uk</li>
              <li>Address: 275 New North Road, London N1 7AA, United Kingdom</li>
            </ul>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default TermsPage;