import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';

const faqs = [
  {
    question: "How does the trade-in process work?",
    answer: "Our trade-in process is simple: First, use our calculator to get an estimated value for your mining hardware. Then, submit your trade-in request. Our team will verify your equipment's condition and finalize the offer. Once accepted, ship your hardware to us, and we'll send your payment or credit toward new equipment."
  },
  {
    question: "What mining hardware do you accept?",
    answer: "We accept most major brands of ASIC miners, including Bitmain Antminer series, MicroBT Whatsminer, and Canaan AvalonMiner. The hardware should be in working condition and not older than 4 years."
  },
  {
    question: "How long does the trade-in process take?",
    answer: "The typical trade-in process takes 5-7 business days from submission to completion. This includes verification, shipping, and final payment or credit issuance."
  },
  {
    question: "Do you offer international shipping?",
    answer: "Yes, we offer international shipping to most countries. Shipping costs and insurance are calculated based on your location and the equipment being shipped."
  },
  {
    question: "What payment methods do you accept?",
    answer: "We accept various payment methods including bank transfers, cryptocurrency (BTC, ETH), and major credit cards for new purchases."
  },
  {
    question: "How do you determine the trade-in value?",
    answer: "Trade-in values are determined based on several factors: current market value, age of the equipment, condition, mining efficiency, and market demand."
  },
  {
    question: "What warranty do you offer on new equipment?",
    answer: "All new mining equipment comes with a manufacturer's warranty. We also offer extended warranty options and technical support for purchased hardware."
  },
  {
    question: "Can I trade multiple units at once?",
    answer: "Yes, we accept bulk trade-ins. For large quantities, please contact our team directly for a custom quote and specialized handling."
  }
];

const FaqPage = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <div className="pt-24 pb-16">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">Frequently Asked Questions</h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Find answers to common questions about our trading process and services
          </p>
        </div>

        <div className="max-w-4xl mx-auto">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="mb-4"
            >
              <button
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                className="w-full text-left bg-gradient-to-r from-blue-900/30 to-purple-900/30 p-6 rounded-lg flex items-center justify-between hover:from-blue-900/40 hover:to-purple-900/40 transition-all"
              >
                <span className="font-semibold text-lg">{faq.question}</span>
                {openIndex === index ? (
                  <ChevronUp className="h-5 w-5 text-blue-500" />
                ) : (
                  <ChevronDown className="h-5 w-5 text-blue-500" />
                )}
              </button>
              {openIndex === index && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  className="bg-black/50 p-6 rounded-b-lg mt-1 text-gray-400"
                >
                  {faq.answer}
                </motion.div>
              )}
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
          className="text-center mt-16"
        >
          <p className="text-gray-400 mb-4">Still have questions?</p>
          <a
            href="/contact"
            className="inline-flex items-center bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all"
          >
            Contact Our Support Team
          </a>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default FaqPage;