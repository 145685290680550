import React from 'react';
import { motion } from 'framer-motion';
import { Cookie, Info, Settings } from 'lucide-react';

const CookiePage = () => {
  const handleManageCookies = () => {
    // Clear existing cookie consent
    localStorage.removeItem('cookieConsent');
    
    // Force reload to show the cookie banner again
    window.location.reload();
  };

  return (
    <div className="pt-24 pb-16">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div className="text-center mb-16">
          <Cookie className="h-12 w-12 text-blue-500 mx-auto mb-4" />
          <h1 className="text-4xl font-bold mb-4">Cookie Policy</h1>
          <p className="text-xl text-gray-400">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>

        <div className="prose prose-invert prose-blue max-w-none">
          <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-2xl p-8 mb-8">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Info className="h-6 w-6 mr-2 text-blue-500" />
              About Cookies
            </h2>
            <p className="text-gray-400">
              Cookies are small text files that are placed on your device when you visit our website. They help us provide you with a better experience and improve our services.
            </p>
          </div>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">1. Types of Cookies We Use</h2>
            <div className="space-y-4 text-gray-400">
              <h3 className="text-xl font-semibold">Essential Cookies</h3>
              <p>Required for basic website functionality:</p>
              <ul className="list-disc list-inside space-y-2">
                <li>Authentication and security</li>
                <li>Shopping cart management</li>
                <li>Session management</li>
              </ul>

              <h3 className="text-xl font-semibold mt-6">Analytics Cookies</h3>
              <p>Help us understand how visitors use our site:</p>
              <ul className="list-disc list-inside space-y-2">
                <li>Page visit statistics</li>
                <li>User behavior analysis</li>
                <li>Performance monitoring</li>
              </ul>

              <h3 className="text-xl font-semibold mt-6">Marketing Cookies</h3>
              <p>Enable enhanced functionality:</p>
              <ul className="list-disc list-inside space-y-2">
                <li>Personalized advertisements</li>
                <li>Retargeting</li>
                <li>Campaign tracking</li>
              </ul>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">2. Cookie Management</h2>
            <div className="space-y-4 text-gray-400">
              <p>You can manage cookies through:</p>
              <ul className="list-disc list-inside space-y-2">
                <li>Browser settings</li>
                <li>Our cookie consent tool</li>
                <li>Third-party opt-out tools</li>
              </ul>
            </div>
          </section>

          <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-2xl p-8 mt-12">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Settings className="h-6 w-6 mr-2 text-blue-500" />
              Cookie Preferences
            </h2>
            <p className="text-gray-400 mb-4">
              You can update your cookie preferences at any time using our cookie settings panel.
            </p>
            <button 
              onClick={handleManageCookies}
              className="bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all"
            >
              Manage Cookie Settings
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CookiePage;