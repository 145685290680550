import React from 'react';
import { useParams } from 'react-router-dom';
import ProductDetails from '../components/ProductDetails';
import ProductSEO from '../components/ProductSEO';
import { products } from '../data/products';

const ProductPage = () => {
  const { category, id } = useParams();
  const productId = parseInt(id?.split('-')[0] || '0');
  const product = products.find(p => p.id === productId);

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="pt-24 pb-16">
      <ProductSEO product={product} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <ProductDetails product={product} onClose={() => {}} />
      </div>
    </div>
  );
};

export default ProductPage;