import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Server, Zap, Shield, Thermometer, Wind, Calculator, ArrowRight } from 'lucide-react';
import HostingCalculator from '../components/HostingCalculator';
import HostingRequestForm from '../components/HostingRequestForm';
import HostingConfirmation from '../components/HostingConfirmation';

const HostingPage = () => {
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [submittedRequest, setSubmittedRequest] = useState<any>(null);

  const features = [
    {
      icon: <Zap className="h-8 w-8" />,
      title: "Competitive Power Rate",
      description: "€0.045 per kWh - among the lowest rates in Europe"
    },
    {
      icon: <Thermometer className="h-8 w-8" />,
      title: "Climate Controlled",
      description: "Air-conditioned facility with optimal temperature control"
    },
    {
      icon: <Wind className="h-8 w-8" />,
      title: "Zero Humidity",
      description: "Dry environment for optimal mining conditions"
    },
    {
      icon: <Shield className="h-8 w-8" />,
      title: "Full Insurance",
      description: "€35/month comprehensive insurance coverage"
    }
  ];

  const handleSubmit = (data: any) => {
    setSubmittedRequest(data);
    setShowRequestForm(false);
  };

  return (
    <div className="pt-24 pb-16">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">ASIC Hosting Solutions</h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Professional hosting for your Bitmain ASIC miners with competitive power rates
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-12 mb-16">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
          >
            <h2 className="text-2xl font-bold mb-6">Why Host With Us?</h2>
            <div className="grid gap-6">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="flex items-start space-x-4"
                >
                  <div className="bg-blue-500/10 p-3 rounded-lg">
                    {feature.icon}
                  </div>
                  <div>
                    <h3 className="font-semibold mb-1">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4 }}
            className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-2xl p-8"
          >
            <h2 className="text-2xl font-bold mb-6">Hosting Requirements</h2>
            <div className="space-y-4">
              <div className="bg-black/40 rounded-lg p-4">
                <h3 className="font-semibold mb-2">Minimum Contract Period</h3>
                <p className="text-gray-400">6 months minimum commitment required</p>
              </div>
              <div className="bg-black/40 rounded-lg p-4">
                <h3 className="font-semibold mb-2">Accepted Hardware</h3>
                <p className="text-gray-400">We accept the following miners:</p>
                <ul className="list-disc list-inside text-gray-400 mt-2 space-y-1">
                  <li>Bitcoin Miners (S19, S21 Series)</li>
                  <li>Hydro-cooling Miners</li>
                  <li>Litecoin Miners (L7 Series)</li>
                  <li>Alephium Miners (AL Series)</li>
                </ul>
              </div>
              <div className="bg-black/40 rounded-lg p-4">
                <h3 className="font-semibold mb-2">Monthly Costs</h3>
                <ul className="space-y-2 text-gray-400">
                  <li>Power: €0.045 per kWh</li>
                  <li>Insurance: €35 per month</li>
                  <li>No setup fees</li>
                </ul>
              </div>
            </div>
          </motion.div>
        </div>

        <HostingCalculator />

        <div className="text-center mt-16">
          <button
            onClick={() => setShowRequestForm(true)}
            className="inline-flex items-center bg-blue-600 hover:bg-blue-700 px-8 py-4 rounded-lg text-lg font-semibold transition-all transform hover:scale-105"
          >
            Start Hosting Request
            <ArrowRight className="ml-2 h-5 w-5" />
          </button>
        </div>

        {showRequestForm && (
          <HostingRequestForm 
            onClose={() => setShowRequestForm(false)}
            onSubmit={handleSubmit}
          />
        )}

        {submittedRequest && (
          <HostingConfirmation
            request={submittedRequest}
            onClose={() => setSubmittedRequest(null)}
          />
        )}
      </motion.div>
    </div>
  );
};

export default HostingPage;