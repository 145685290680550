import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  LayoutDashboard, 
  ArrowRightLeft, 
  Package, 
  Settings, 
  User, 
  LogOut, 
  Wrench,
  Server,
  BarChart3,
  DollarSign,
  Users,
  Clock,
  Tags,
  CircleDollarSign,
  AlertTriangle,
  ArrowRight
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../stores/authStore';
import { useAdminDashboard } from '../../hooks/useAdminDashboard';
import { formatPrice } from '../../utils/priceFormatter';
import OrdersManagement from './OrdersManagement';
import ProductsManagement from './ProductsManagement';
import CategoryManagement from './CategoryManagement';
import RepairManagement from './RepairManagement';
import TradeInManagement from './TradeInManagement';
import UsersManagement from './UsersManagement';
import SettingsManagement from './SettingsManagement';

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const navigate = useNavigate();
  const signOut = useAuthStore(state => state.signOut);
  const { stats, recentOrders, recentTradeIns, recentRepairs } = useAdminDashboard();

  const handleLogout = async () => {
    await signOut();
    navigate('/login');
  };

  const renderOverview = () => {
    if (stats.isLoading || recentOrders.isLoading || recentTradeIns.isLoading || recentRepairs.isLoading) {
      return (
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      );
    }

    return (
      <div className="space-y-6">
        <div className="grid md:grid-cols-3 gap-6">
          <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-xl p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">Monthly Revenue</h3>
              <CircleDollarSign className="h-6 w-6 text-blue-500" />
            </div>
            <div className="text-3xl font-bold text-blue-500">
              {formatPrice(stats.data?.monthlyRevenue || 0)}
            </div>
          </div>

          <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-xl p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">Pending Orders</h3>
              <AlertTriangle className="h-6 w-6 text-yellow-500" />
            </div>
            <div className="text-3xl font-bold">
              {stats.data?.pendingOrders || 0}
            </div>
          </div>

          <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-xl p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">Active Trade-Ins</h3>
              <ArrowRightLeft className="h-6 w-6 text-blue-500" />
            </div>
            <div className="text-3xl font-bold">
              {stats.data?.pendingTradeIns || 0}
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-xl p-6">
            <h3 className="text-lg font-semibold mb-4">Recent Orders</h3>
            <div className="space-y-4">
              {recentOrders.data?.map((order) => (
                <div key={order.id} className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <Package className="h-5 w-5 text-blue-500" />
                    <div>
                      <p className="font-semibold">{order.order_number}</p>
                      <p className="text-sm text-gray-400">
                        {order.items?.[0]?.product?.name}
                        {order.items?.length > 1 ? ` +${order.items.length - 1} more` : ''}
                      </p>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className="font-semibold">{formatPrice(order.total_amount)}</p>
                    <p className="text-sm text-gray-400">
                      {new Date(order.created_at).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-xl p-6">
            <h3 className="text-lg font-semibold mb-4">Recent Trade-Ins</h3>
            <div className="space-y-4">
              {recentTradeIns.data?.map((tradeIn) => (
                <div key={tradeIn.id} className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <ArrowRightLeft className="h-5 w-5 text-blue-500" />
                    <div>
                      <p className="font-semibold">{tradeIn.miner_model}</p>
                      <p className="text-sm text-gray-400">S/N: {tradeIn.serial_number}</p>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className="font-semibold">{formatPrice(tradeIn.estimated_value)}</p>
                    <p className="text-sm text-gray-400">
                      {new Date(tradeIn.created_at).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-xl p-6">
          <h3 className="text-lg font-semibold mb-4">Recent Repairs</h3>
          <div className="space-y-4">
            {recentRepairs.data?.map((repair) => (
              <div key={repair.id} className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <Wrench className="h-5 w-5 text-blue-500" />
                  <div>
                    <p className="font-semibold">{repair.model}</p>
                    <p className="text-sm text-gray-400">
                      {repair.device_type} - {repair.serial_number}
                    </p>
                  </div>
                </div>
                <div className="text-right">
                  <span className={`px-2 py-1 rounded-full text-sm ${
                    repair.status === 'completed' ? 'bg-green-500/20 text-green-400' :
                    repair.status === 'pending' ? 'bg-yellow-500/20 text-yellow-400' :
                    repair.status === 'in_progress' ? 'bg-blue-500/20 text-blue-400' :
                    'bg-red-500/20 text-red-400'
                  }`}>
                    {repair.status}
                  </span>
                  <p className="text-sm text-gray-400 mt-1">
                    {new Date(repair.created_at).toLocaleDateString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'orders':
        return <OrdersManagement />;
      case 'products':
        return <ProductsManagement />;
      case 'categories':
        return <CategoryManagement />;
      case 'repairs':
        return <RepairManagement />;
      case 'trade-ins':
        return <TradeInManagement />;
      case 'users':
        return <UsersManagement />;
      case 'settings':
        return <SettingsManagement />;
      default:
        return renderOverview();
    }
  };

  return (
    <div className="min-h-screen bg-black">
      <div className="flex">
        {/* Sidebar */}
        <div className="w-64 bg-gradient-to-b from-blue-900/40 to-black/40 min-h-screen fixed">
          <div className="p-6">
            <h1 className="text-xl font-bold mb-8">Admin Dashboard</h1>
            <nav className="space-y-2">
              <button
                onClick={() => setActiveTab('overview')}
                className={`w-full text-left px-4 py-2 rounded-lg transition-colors flex items-center space-x-3 ${
                  activeTab === 'overview' ? 'bg-blue-600' : 'hover:bg-white/10'
                }`}
              >
                <BarChart3 className="h-5 w-5" />
                <span>Overview</span>
              </button>

              <button
                onClick={() => setActiveTab('orders')}
                className={`w-full text-left px-4 py-2 rounded-lg transition-colors flex items-center space-x-3 ${
                  activeTab === 'orders' ? 'bg-blue-600' : 'hover:bg-white/10'
                }`}
              >
                <Package className="h-5 w-5" />
                <span>Orders</span>
              </button>

              <button
                onClick={() => setActiveTab('products')}
                className={`w-full text-left px-4 py-2 rounded-lg transition-colors flex items-center space-x-3 ${
                  activeTab === 'products' ? 'bg-blue-600' : 'hover:bg-white/10'
                }`}
              >
                <LayoutDashboard className="h-5 w-5" />
                <span>Products</span>
              </button>

              <button
                onClick={() => setActiveTab('categories')}
                className={`w-full text-left px-4 py-2 rounded-lg transition-colors flex items-center space-x-3 ${
                  activeTab === 'categories' ? 'bg-blue-600' : 'hover:bg-white/10'
                }`}
              >
                <Tags className="h-5 w-5" />
                <span>Categories</span>
              </button>

              <button
                onClick={() => setActiveTab('repairs')}
                className={`w-full text-left px-4 py-2 rounded-lg transition-colors flex items-center space-x-3 ${
                  activeTab === 'repairs' ? 'bg-blue-600' : 'hover:bg-white/10'
                }`}
              >
                <Wrench className="h-5 w-5" />
                <span>Repairs</span>
              </button>

              <button
                onClick={() => setActiveTab('trade-ins')}
                className={`w-full text-left px-4 py-2 rounded-lg transition-colors flex items-center space-x-3 ${
                  activeTab === 'trade-ins' ? 'bg-blue-600' : 'hover:bg-white/10'
                }`}
              >
                <ArrowRightLeft className="h-5 w-5" />
                <span>Trade-Ins</span>
              </button>

              <button
                onClick={() => setActiveTab('users')}
                className={`w-full text-left px-4 py-2 rounded-lg transition-colors flex items-center space-x-3 ${
                  activeTab === 'users' ? 'bg-blue-600' : 'hover:bg-white/10'
                }`}
              >
                <User className="h-5 w-5" />
                <span>Users</span>
              </button>

              <button
                onClick={() => setActiveTab('settings')}
                className={`w-full text-left px-4 py-2 rounded-lg transition-colors flex items-center space-x-3 ${
                  activeTab === 'settings' ? 'bg-blue-600' : 'hover:bg-white/10'
                }`}
              >
                <Settings className="h-5 w-5" />
                <span>Settings</span>
              </button>

              <button
                onClick={handleLogout}
                className="w-full text-left px-4 py-2 rounded-lg transition-colors flex items-center space-x-3 text-red-500 hover:bg-white/10"
              >
                <LogOut className="h-5 w-5" />
                <span>Logout</span>
              </button>
            </nav>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 ml-64">
          <div className="p-8">
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;