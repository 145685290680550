import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { X, Upload, Save } from 'lucide-react';
import { useCategories } from '../../hooks/useCategories';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../lib/supabase';
import { Product } from '../../data/types';
import { useSupabaseImage } from '../../hooks/useSupabaseImage';
import { STORAGE_BUCKETS } from '../../lib/supabase';

interface ProductFormProps {
  product?: Product;
  onSubmit: (data: FormData) => void;
  onClose: () => void;
}

const ProductForm = ({ product, onSubmit, onClose }: ProductFormProps) => {
  const [formData, setFormData] = useState({
    name: product?.name || '',
    price: product?.price || 0,
    category_id: product?.category_id?.toString() || '',
    manufacturer_id: product?.manufacturer_id?.toString() || '',
    hashrate: product?.hashrate || '',
    algorithm: product?.algorithm || '',
    stock: product?.stock || '',
    power_consumption: product?.power_consumption || '',
    noise_level: product?.noise_level || '',
    network_interface: product?.network_interface || '',
    cooling: product?.cooling || '',
    warranty: product?.warranty || '',
    efficiency: product?.efficiency || '',
    dimensions: product?.dimensions || '',
    weight: product?.weight || '',
    operating_temperature: product?.operating_temperature || '',
    humidity_range: product?.humidity_range || '',
    power_supply: product?.power_supply || '',
    fan_count: product?.fan_count || 0,
    chip_count: product?.chip_count || 0,
    rated_voltage: product?.rated_voltage || '',
    rated_frequency: product?.rated_frequency || '',
    control_board: product?.control_board || '',
    firmware_version: product?.firmware_version || ''
  });

  const [image, setImage] = useState<File | null>(null);
  const { categories } = useCategories();
  const imageUrl = useSupabaseImage(STORAGE_BUCKETS.PRODUCTS, product?.image || '');

  const { data: manufacturers, isLoading: manufacturersLoading } = useQuery({
    queryKey: ['manufacturers'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('manufacturers')
        .select('*')
        .order('name');
      if (error) throw error;
      return data;
    }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    const form = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== '') {
        form.append(key, value.toString());
      }
    });
    
    if (image) {
      form.append('image', image);
    }
    
    if (product?.id) {
      form.append('id', product.id.toString());
    }
    
    onSubmit(form);
  };

  if (categories.isLoading || manufacturersLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-gradient-to-b from-blue-900/40 to-black/40 rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto border border-white/10"
      >
        <div className="sticky top-0 bg-black/90 backdrop-blur-sm p-6 border-b border-white/10 flex justify-between items-center">
          <h2 className="text-2xl font-bold">
            {product ? 'Edit Product' : 'Add New Product'}
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-lg transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium mb-2">Name</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Category</label>
                <select
                  value={formData.category_id}
                  onChange={(e) => setFormData({ ...formData, category_id: e.target.value })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                >
                  <option value="">Select category</option>
                  {categories.data?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Manufacturer</label>
                <select
                  value={formData.manufacturer_id}
                  onChange={(e) => setFormData({ ...formData, manufacturer_id: e.target.value })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                >
                  <option value="">Select manufacturer</option>
                  {manufacturers?.map((manufacturer) => (
                    <option key={manufacturer.id} value={manufacturer.id}>
                      {manufacturer.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Price (€)</label>
                <input
                  type="number"
                  value={formData.price}
                  onChange={(e) => setFormData({ ...formData, price: parseFloat(e.target.value) })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                  min="0"
                  step="0.01"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Hashrate</label>
                <input
                  type="text"
                  value={formData.hashrate}
                  onChange={(e) => setFormData({ ...formData, hashrate: e.target.value })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Algorithm</label>
                <input
                  type="text"
                  value={formData.algorithm}
                  onChange={(e) => setFormData({ ...formData, algorithm: e.target.value })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Stock Status</label>
                <input
                  type="text"
                  value={formData.stock}
                  onChange={(e) => setFormData({ ...formData, stock: e.target.value })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            </div>

            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium mb-2">Product Image</label>
                <div className="border-2 border-dashed border-white/10 rounded-lg p-4 text-center">
                  {(imageUrl || image) && (
                    <img
                      src={image ? URL.createObjectURL(image) : imageUrl}
                      alt="Preview"
                      className="mx-auto mb-4 max-h-48 rounded-lg"
                    />
                  )}
                  <label className="cursor-pointer">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => e.target.files && setImage(e.target.files[0])}
                      className="hidden"
                    />
                    <div className="bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg inline-flex items-center gap-2">
                      <Upload className="h-5 w-5" />
                      <span>{image ? 'Change Image' : 'Upload Image'}</span>
                    </div>
                  </label>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Power Consumption</label>
                <input
                  type="text"
                  value={formData.power_consumption}
                  onChange={(e) => setFormData({ ...formData, power_consumption: e.target.value })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Noise Level</label>
                <input
                  type="text"
                  value={formData.noise_level}
                  onChange={(e) => setFormData({ ...formData, noise_level: e.target.value })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Network Interface</label>
                <input
                  type="text"
                  value={formData.network_interface}
                  onChange={(e) => setFormData({ ...formData, network_interface: e.target.value })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Cooling</label>
                <input
                  type="text"
                  value={formData.cooling}
                  onChange={(e) => setFormData({ ...formData, cooling: e.target.value })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Warranty</label>
                <input
                  type="text"
                  value={formData.warranty}
                  onChange={(e) => setFormData({ ...formData, warranty: e.target.value })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Efficiency</label>
                <input
                  type="text"
                  value={formData.efficiency}
                  onChange={(e) => setFormData({ ...formData, efficiency: e.target.value })}
                  className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-3 gap-6 mt-6">
            <div>
              <label className="block text-sm font-medium mb-2">Dimensions</label>
              <input
                type="text"
                value={formData.dimensions}
                onChange={(e) => setFormData({ ...formData, dimensions: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Weight</label>
              <input
                type="text"
                value={formData.weight}
                onChange={(e) => setFormData({ ...formData, weight: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Operating Temperature</label>
              <input
                type="text"
                value={formData.operating_temperature}
                onChange={(e) => setFormData({ ...formData, operating_temperature: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Humidity Range</label>
              <input
                type="text"
                value={formData.humidity_range}
                onChange={(e) => setFormData({ ...formData, humidity_range: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Power Supply</label>
              <input
                type="text"
                value={formData.power_supply}
                onChange={(e) => setFormData({ ...formData, power_supply: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Fan Count</label>
              <input
                type="number"
                value={formData.fan_count}
                onChange={(e) => setFormData({ ...formData, fan_count: parseInt(e.target.value) })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Chip Count</label>
              <input
                type="number"
                value={formData.chip_count}
                onChange={(e) => setFormData({ ...formData, chip_count: parseInt(e.target.value) })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Rated Voltage</label>
              <input
                type="text"
                value={formData.rated_voltage}
                onChange={(e) => setFormData({ ...formData, rated_voltage: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Rated Frequency</label>
              <input
                type="text"
                value={formData.rated_frequency}
                onChange={(e) => setFormData({ ...formData, rated_frequency: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>

          <div className="flex justify-end gap-4 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-3 rounded-lg border border-white/10 hover:bg-white/10 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-colors flex items-center gap-2"
            >
              <Save className="h-5 w-5" />
              <span>{product ? 'Update Product' : 'Add Product'}</span>
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default ProductForm;