import { useQuery } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';

export const useDashboardData = () => {
  const getOrders = async () => {
    const { data, error } = await supabase
      .from('orders')
      .select('*')
      .order('created_at', { ascending: false });
    if (error) throw error;
    return data;
  };

  const getRepairRequests = async () => {
    const { data, error } = await supabase
      .from('repair_requests')
      .select('*')
      .order('created_at', { ascending: false });
    if (error) throw error;
    return data;
  };

  const getTradeIns = async () => {
    const { data, error } = await supabase
      .from('trade_in_requests')
      .select('*')
      .order('created_at', { ascending: false });
    if (error) throw error;
    return data;
  };

  return {
    orders: useQuery({
      queryKey: ['orders'],
      queryFn: getOrders
    }),
    repairs: useQuery({
      queryKey: ['repairs'],
      queryFn: getRepairRequests
    }),
    tradeIns: useQuery({
      queryKey: ['trade-ins'],
      queryFn: getTradeIns
    })
  };
};