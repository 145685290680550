import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryProvider } from './providers/QueryProvider';
import Routes from './Routes';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';

function App() {
  return (
    <QueryProvider>
      <HelmetProvider>
        <Router>
          <div className="min-h-screen bg-black text-white flex flex-col">
            <Navbar />
            <main className="flex-grow">
              <Routes />
            </main>
            <Footer />
            <CookieConsent />
          </div>
        </Router>
      </HelmetProvider>
    </QueryProvider>
  );
}

export default App;