import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';
import { useSupabaseStorage } from './useSupabaseStorage';
import { STORAGE_BUCKETS } from '../lib/supabase';

export const useAdminProducts = () => {
  const queryClient = useQueryClient();
  const { uploadProductImage, deleteProductImage } = useSupabaseStorage();

  const addProduct = useMutation({
    mutationFn: async ({ image, ...product }: any) => {
      try {
        // First create the product to get an ID
        const { data: newProduct, error: productError } = await supabase
          .from('products')
          .insert({
            ...product,
            image: '' // Temporary empty image URL
          })
          .select()
          .single();

        if (productError) throw productError;

        // Upload the image using the new product ID
        const imageUrl = await uploadProductImage(image, newProduct.id);

        // Update the product with the image URL
        const { data, error: updateError } = await supabase
          .from('products')
          .update({ image: imageUrl })
          .eq('id', newProduct.id)
          .select()
          .single();

        if (updateError) throw updateError;
        return data;
      } catch (error) {
        console.error('Error saving product:', error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['products'] });
    }
  });

  const updateProduct = useMutation({
    mutationFn: async ({ id, image, ...product }: any) => {
      try {
        let imageUrl = product.image;

        if (image) {
          // Delete old image if it exists
          if (product.image) {
            await deleteProductImage(product.image);
          }
          // Upload new image
          imageUrl = await uploadProductImage(image, id);
        }

        const { data, error } = await supabase
          .from('products')
          .update({ ...product, image: imageUrl })
          .eq('id', id)
          .select()
          .single();

        if (error) throw error;
        return data;
      } catch (error) {
        console.error('Error updating product:', error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['products'] });
    }
  });

  const deleteProduct = useMutation({
    mutationFn: async (id: number) => {
      try {
        // Get product to find image URL
        const { data: product } = await supabase
          .from('products')
          .select('image')
          .eq('id', id)
          .single();

        if (product?.image) {
          await deleteProductImage(product.image);
        }

        const { error } = await supabase
          .from('products')
          .delete()
          .eq('id', id);

        if (error) throw error;
      } catch (error) {
        console.error('Error deleting product:', error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['products'] });
    }
  });

  return {
    addProduct,
    updateProduct,
    deleteProduct
  };
};