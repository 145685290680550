import React from 'react';
import { Helmet } from 'react-helmet-async';

interface ProductSEOProps {
  product: {
    id: number;
    name: string;
    price: number;
    description?: string;
    category: string;
    hashrate: string;
    algorithm: string;
    image: string;
  };
}

const ProductSEO = ({ product }: ProductSEOProps) => {
  const productUrl = `https://euronet-trading.co.uk/products/${product.category.toLowerCase().replace(/\s+/g, '-')}/${product.id}-${product.name.toLowerCase().replace(/\s+/g, '-')}`;
  
  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": product.name,
    "image": product.image,
    "description": `${product.name} - ${product.hashrate} ${product.algorithm} Miner | Available at Euronet Trading`,
    "brand": {
      "@type": "Brand",
      "name": "Euronet Trading"
    },
    "offers": {
      "@type": "Offer",
      "url": productUrl,
      "priceCurrency": "EUR",
      "price": product.price,
      "availability": "https://schema.org/InStock"
    }
  };

  return (
    <Helmet>
      <title>{`${product.name} | ${product.hashrate} Mining Hardware | Euronet Trading`}</title>
      <meta name="description" content={`Buy ${product.name} with ${product.hashrate} hashrate for ${product.algorithm} mining. Available now at Euronet Trading with trade-in options and warranty.`} />
      <meta name="keywords" content={`${product.name}, ${product.algorithm} miner, crypto mining hardware, ${product.category}, mining equipment`} />
      
      <link rel="canonical" href={productUrl} />
      
      <meta property="og:title" content={`${product.name} | Professional Mining Hardware`} />
      <meta property="og:description" content={`${product.name} with ${product.hashrate} hashrate for ${product.algorithm} mining. Professional mining equipment available at Euronet Trading.`} />
      <meta property="og:image" content={product.image} />
      <meta property="og:url" content={productUrl} />
      
      <meta name="twitter:title" content={`${product.name} | Professional Mining Hardware`} />
      <meta name="twitter:description" content={`${product.name} with ${product.hashrate} hashrate for ${product.algorithm} mining. Professional mining equipment available at Euronet Trading.`} />
      <meta name="twitter:image" content={product.image} />
      
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default ProductSEO;