import { useMemo } from 'react';
import { supabase } from '../lib/supabase';

export const useSupabaseImage = (bucket: string, path: string | null) => {
  return useMemo(() => {
    if (!path) return null;

    // If it's already a full URL, return it
    try {
      new URL(path);
      return path;
    } catch {
      // If path is not a valid URL, construct storage URL
      const { data: { publicUrl } } = supabase.storage
        .from(bucket)
        .getPublicUrl(path);
      
      return publicUrl;
    }
  }, [bucket, path]);
};