import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';
import { SiteSettings } from '../data/types';

export const useSettings = () => {
  const queryClient = useQueryClient();

  const getSettings = async () => {
    const { data, error } = await supabase
      .from('settings')
      .select('*')
      .single();

    if (error) throw error;
    return data as SiteSettings;
  };

  const updateSettings = useMutation({
    mutationFn: async (settings: SiteSettings) => {
      const { error } = await supabase
        .from('settings')
        .upsert(settings);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });
    }
  });

  return {
    settings: useQuery({
      queryKey: ['settings'],
      queryFn: getSettings
    }),
    updateSettings
  };
};