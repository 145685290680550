import React from 'react';
import { motion } from 'framer-motion';
import { Zap, Activity, Server } from 'lucide-react';
import { Product } from '../data/types';
import { formatPrice } from '../utils/priceFormatter';
import { STORAGE_BUCKETS } from '../lib/supabase';
import { useSupabaseImage } from '../hooks/useSupabaseImage';

interface ProductCardProps {
  product: Product;
  onClick: () => void;
}

const ProductCard = ({ product, onClick }: ProductCardProps) => {
  const imageUrl = useSupabaseImage(STORAGE_BUCKETS.PRODUCTS, product.image);
  const fallbackImage = 'https://images.unsplash.com/photo-1640079421264-61f9d6f3efb1?auto=format&fit=crop&q=80&w=600&h=400';

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl overflow-hidden border border-white/10 hover:border-blue-500/50 transition-all cursor-pointer"
      onClick={onClick}
    >
      <div className="relative h-48">
        <img
          src={imageUrl || fallbackImage}
          alt={product.name}
          className="w-full h-full object-cover"
          onError={(e) => {
            e.currentTarget.src = fallbackImage;
          }}
        />
        {product.stock && (
          <div className="absolute top-4 right-4 bg-blue-600 px-3 py-1 rounded-full text-sm">
            {product.stock}
          </div>
        )}
      </div>
      
      <div className="p-6">
        <div className="flex items-start justify-between mb-4">
          <div>
            <h3 className="text-lg font-semibold">{product.name}</h3>
            <p className="text-sm text-gray-400">{product.category}</p>
          </div>
          <div className="text-xl font-bold text-blue-500">
            {formatPrice(product.price)}
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex items-center text-gray-400">
            <Zap className="h-4 w-4 mr-2 text-blue-500" />
            <span>{product.hashrate}</span>
          </div>
          {product.efficiency && (
            <div className="flex items-center text-gray-400">
              <Activity className="h-4 w-4 mr-2 text-blue-500" />
              <span>{product.efficiency}</span>
            </div>
          )}
          <div className="flex items-center text-gray-400">
            <Server className="h-4 w-4 mr-2 text-blue-500" />
            <span>{product.algorithm}</span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProductCard;