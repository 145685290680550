import React from 'react';
import { Shield, Zap, ArrowRightLeft, Cpu } from 'lucide-react';

const Features = () => {
  const features = [
    {
      icon: <Shield className="h-8 w-8 text-blue-500" />,
      title: 'Secure Trading',
      description: 'Every trade is verified and secured through our professional team'
    },
    {
      icon: <Zap className="h-8 w-8 text-blue-500" />,
      title: 'Instant Quotes',
      description: 'Get immediate value estimates for your mining hardware'
    },
    {
      icon: <ArrowRightLeft className="h-8 w-8 text-blue-500" />,
      title: 'Easy Exchange',
      description: 'Seamless process to upgrade your mining equipment'
    },
    {
      icon: <Cpu className="h-8 w-8 text-blue-500" />,
      title: 'Latest Models',
      description: 'Access to the newest and most efficient mining hardware'
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
      <div className="text-center mb-16">
        <h2 className="text-3xl font-bold mb-4">Why Choose Euronet Trading?</h2>
        <p className="text-gray-400 max-w-2xl mx-auto">
          We make trading your mining hardware simple, secure, and profitable
        </p>
      </div>
      
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="bg-gradient-to-b from-blue-900/20 to-transparent p-6 rounded-xl border border-white/10 hover:border-blue-500/50 transition-all duration-300"
          >
            <div className="bg-black/40 rounded-lg p-3 w-fit mb-4">
              {feature.icon}
            </div>
            <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
            <p className="text-gray-400">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;