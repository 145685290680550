interface MinerModel {
  id: string;
  name: string;
  category: string;
  value: number;
}

export const MINER_MODELS: MinerModel[] = [
  // Bitcoin Miners
  {
    id: 'antminer-s19-pro',
    name: 'Antminer S19 Pro (110Th)',
    category: 'Bitcoin Miners',
    value: 400
  },
  {
    id: 'antminer-s19j-pro',
    name: 'Antminer S19j Pro (104Th)',
    category: 'Bitcoin Miners',
    value: 350
  },
  {
    id: 'antminer-s19-xp',
    name: 'Antminer S19 XP (140Th)',
    category: 'Bitcoin Miners',
    value: 600
  },
  {
    id: 'whatsminer-m30s',
    name: 'Whatsminer M30S (90Th)',
    category: 'Bitcoin Miners',
    value: 300
  },
  {
    id: 'whatsminer-m30s-plus',
    name: 'Whatsminer M30S+ (100Th)',
    category: 'Bitcoin Miners',
    value: 320
  },
  {
    id: 'whatsminer-m50',
    name: 'Whatsminer M50 (118Th)',
    category: 'Bitcoin Miners',
    value: 450
  },

  // Litecoin Miners
  {
    id: 'antminer-l7',
    name: 'Antminer L7 (9.5Gh)',
    category: 'Litecoin Miners',
    value: 2000
  },
  {
    id: 'antminer-l3-plus',
    name: 'Antminer L3+ (504Mh)',
    category: 'Litecoin Miners',
    value: 100
  },
  {
    id: 'goldshell-lt6',
    name: 'Goldshell LT6 (2.2Gh)',
    category: 'Litecoin Miners',
    value: 800
  },

  // Ethereum Miners
  {
    id: 'antminer-e9-pro',
    name: 'Antminer E9 Pro (3.68Gh)',
    category: 'Ethereum Miners',
    value: 1200
  },
  {
    id: 'innosilicon-a11',
    name: 'Innosilicon A11 (2.0Gh)',
    category: 'Ethereum Miners',
    value: 800
  },

  // Kadena Miners
  {
    id: 'goldshell-kd6',
    name: 'Goldshell KD6 (35Th)',
    category: 'Kadena Miners',
    value: 900
  },
  {
    id: 'kd-box-pro',
    name: 'KD Box Pro (2.6Th)',
    category: 'Kadena Miners',
    value: 200
  },

  // Hydro Cooling Miners
  {
    id: 'antminer-s19-pro-hyd',
    name: 'Antminer S19 Pro+ Hyd (198Th)',
    category: 'Hydro Cooling Miners',
    value: 800
  },
  {
    id: 'antminer-s19-xp-hyd',
    name: 'Antminer S19 XP Hyd (255Th)',
    category: 'Hydro Cooling Miners',
    value: 1000
  },
];