import React from 'react';
import { motion } from 'framer-motion';
import { Lock, Shield, Eye, Database, Bell, Key, CloudLightning } from 'lucide-react';

const PrivacyPage = () => {
  return (
    <div className="pt-24 pb-16">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div className="text-center mb-16">
          <Lock className="h-12 w-12 text-blue-500 mx-auto mb-4" />
          <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
          <p className="text-xl text-gray-400">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>

        <div className="prose prose-invert prose-blue max-w-none">
          <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-2xl p-8 mb-8">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Eye className="h-6 w-6 mr-2 text-blue-500" />
              1. Information We Collect
            </h2>
            <div className="space-y-4 text-gray-400">
              <p>
                We collect information that you provide directly to us, including:
              </p>
              <ul className="list-disc list-inside space-y-2">
                <li>Name and contact information</li>
                <li>Shipping and billing addresses</li>
                <li>Mining hardware details and specifications</li>
                <li>Trade-in equipment information</li>
                <li>Payment information</li>
                <li>Communication preferences</li>
              </ul>
            </div>
          </div>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Key className="h-6 w-6 mr-2 text-blue-500" />
              2. How We Use Your Information
            </h2>
            <div className="space-y-4 text-gray-400">
              <p>We use the collected information for:</p>
              <ul className="list-disc list-inside space-y-2">
                <li>Processing your orders and trade-ins</li>
                <li>Managing your account and providing customer support</li>
                <li>Sending important updates about your transactions</li>
                <li>Improving our services and user experience</li>
                <li>Complying with legal obligations</li>
                <li>Preventing fraud and maintaining security</li>
              </ul>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Shield className="h-6 w-6 mr-2 text-blue-500" />
              3. Data Security
            </h2>
            <div className="space-y-4 text-gray-400">
              <p>
                We implement appropriate technical and organizational measures to protect your personal data, including:
              </p>
              <ul className="list-disc list-inside space-y-2">
                <li>Encryption of sensitive data</li>
                <li>Regular security assessments</li>
                <li>Access controls and authentication</li>
                <li>Secure data storage and transmission</li>
                <li>Employee training on data protection</li>
              </ul>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <CloudLightning className="h-6 w-6 mr-2 text-blue-500" />
              4. Third-Party Services
            </h2>
            <div className="space-y-4 text-gray-400">
              <p>
                We may share your information with trusted third parties for:
              </p>
              <ul className="list-disc list-inside space-y-2">
                <li>Payment processing</li>
                <li>Shipping and logistics</li>
                <li>Customer support services</li>
                <li>Analytics and performance monitoring</li>
              </ul>
              <p>
                All third-party service providers are contractually obligated to protect your data and use it only for specified purposes.
              </p>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Bell className="h-6 w-6 mr-2 text-blue-500" />
              5. Your Rights and Choices
            </h2>
            <div className="space-y-4 text-gray-400">
              <p>You have the right to:</p>
              <ul className="list-disc list-inside space-y-2">
                <li>Access your personal data</li>
                <li>Correct inaccurate information</li>
                <li>Request deletion of your data</li>
                <li>Object to data processing</li>
                <li>Withdraw consent at any time</li>
                <li>Request data portability</li>
              </ul>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">6. Cookies and Tracking</h2>
            <div className="space-y-4 text-gray-400">
              <p>
                We use cookies and similar technologies to:
              </p>
              <ul className="list-disc list-inside space-y-2">
                <li>Remember your preferences</li>
                <li>Analyze site usage</li>
                <li>Personalize your experience</li>
                <li>Improve our services</li>
              </ul>
              <p>
                You can manage cookie preferences through your browser settings. For more information, please see our Cookie Policy.
              </p>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">7. International Data Transfers</h2>
            <div className="space-y-4 text-gray-400">
              <p>
                Your information may be transferred to and processed in countries outside your residence. We ensure appropriate safeguards are in place to protect your data during such transfers.
              </p>
            </div>
          </section>

          <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-2xl p-8 mt-12">
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Database className="h-6 w-6 mr-2 text-blue-500" />
              Contact Information
            </h2>
            <p className="text-gray-400 mb-4">
              For any privacy-related questions or concerns, please contact our Data Protection Officer at:
            </p>
            <ul className="text-gray-400 space-y-2">
              <li>Email: privacy@euronet-trading.co.uk</li>
              <li>Address: 275 New North Road, London N1 7AA, United Kingdom</li>
            </ul>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default PrivacyPage;