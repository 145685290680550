import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FileText, 
  PackageCheck, 
  Printer, 
  Truck, 
  CheckCircle2, 
  AlertTriangle,
  Camera,
  Box,
  PackageX,
  Ruler,
  X
} from 'lucide-react';

interface TradeInProcessProps {
  selectedMiner: string;
  estimatedValue: number;
  onClose: () => void;
}

const TradeInProcess = ({ selectedMiner, estimatedValue, onClose }: TradeInProcessProps) => {
  const [step, setStep] = useState(1);
  const [agreement, setAgreement] = useState(false);
  const [photos, setPhotos] = useState<string[]>([]);
  const [shippingLabel, setShippingLabel] = useState('');

  const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newPhotos = Array.from(e.target.files).map(file => URL.createObjectURL(file));
      setPhotos([...photos, ...newPhotos]);
    }
  };

  const generateShippingLabel = () => {
    // In a real application, this would call an API to generate a shipping label
    const trackingNumber = Math.random().toString(36).substring(7).toUpperCase();
    setShippingLabel(trackingNumber);
  };

  const renderStep = () => {
    switch(step) {
      case 1:
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-bold mb-4">Trade-In Agreement</h3>
            
            <div className="bg-black/40 rounded-xl p-6 space-y-4">
              <div className="flex items-start space-x-3">
                <FileText className="h-5 w-5 text-blue-500 mt-1" />
                <div>
                  <h4 className="font-semibold">Terms of Trade</h4>
                  <ul className="list-disc list-inside text-gray-400 text-sm mt-2">
                    <li>Equipment must match described condition</li>
                    <li>All original parts must be included</li>
                    <li>No visible damage or tampering</li>
                    <li>Final value subject to inspection</li>
                  </ul>
                </div>
              </div>
              
              <div className="flex items-center space-x-3 mt-4">
                <input
                  type="checkbox"
                  id="agreement"
                  checked={agreement}
                  onChange={(e) => setAgreement(e.target.checked)}
                  className="rounded border-gray-400 text-blue-600 focus:ring-blue-500"
                />
                <label htmlFor="agreement" className="text-sm text-gray-400">
                  I agree to the terms and conditions of the trade-in program
                </label>
              </div>
            </div>
            
            <button
              onClick={() => setStep(2)}
              disabled={!agreement}
              className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Continue to Documentation
            </button>
          </div>
        );
      
      case 2:
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-bold mb-4">Equipment Documentation</h3>
            
            <div className="bg-black/40 rounded-xl p-6 space-y-6">
              <div className="flex items-start space-x-3">
                <Camera className="h-5 w-5 text-blue-500 mt-1" />
                <div>
                  <h4 className="font-semibold">Required Photos</h4>
                  <ul className="list-disc list-inside text-gray-400 text-sm mt-2">
                    <li>Front view with model number visible</li>
                    <li>Back view with ports visible</li>
                    <li>Close-up of any damage or wear</li>
                    <li>Serial number label</li>
                  </ul>
                </div>
              </div>
              
              <div className="mt-4">
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handlePhotoUpload}
                  className="hidden"
                  id="photo-upload"
                />
                <label
                  htmlFor="photo-upload"
                  className="block w-full bg-blue-600/20 hover:bg-blue-600/30 border-2 border-dashed border-blue-500 rounded-lg p-4 text-center cursor-pointer transition-colors"
                >
                  <Camera className="h-8 w-8 text-blue-500 mx-auto mb-2" />
                  <span className="text-sm text-gray-400">
                    Click to upload photos or drag and drop
                  </span>
                </label>
              </div>
              
              {photos.length > 0 && (
                <div className="grid grid-cols-2 gap-4 mt-4">
                  {photos.map((photo, index) => (
                    <div key={index} className="relative rounded-lg overflow-hidden">
                      <img src={photo} alt={`Upload ${index + 1}`} className="w-full h-32 object-cover" />
                      <button
                        onClick={() => setPhotos(photos.filter((_, i) => i !== index))}
                        className="absolute top-2 right-2 bg-black/60 p-1 rounded-full hover:bg-black/80 transition-colors"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            
            <button
              onClick={() => setStep(3)}
              disabled={photos.length < 4}
              className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Continue to Packing Instructions
            </button>
          </div>
        );
      
      case 3:
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-bold mb-4">Packing Instructions</h3>
            
            <div className="bg-black/40 rounded-xl p-6 space-y-6">
              <div className="flex items-start space-x-3">
                <Box className="h-5 w-5 text-blue-500 mt-1" />
                <div>
                  <h4 className="font-semibold">Box Requirements</h4>
                  <ul className="list-disc list-inside text-gray-400 text-sm mt-2">
                    <li>Strong corrugated cardboard box</li>
                    <li>Minimum 2 inches larger than miner on all sides</li>
                    <li>Double-walled preferred for heavy miners</li>
                  </ul>
                </div>
              </div>
              
              <div className="flex items-start space-x-3">
                <PackageX className="h-5 w-5 text-blue-500 mt-1" />
                <div>
                  <h4 className="font-semibold">Packing Materials</h4>
                  <ul className="list-disc list-inside text-gray-400 text-sm mt-2">
                    <li>Anti-static bubble wrap for primary protection</li>
                    <li>Foam padding for corners and edges</li>
                    <li>Packing peanuts or air pillows for void fill</li>
                  </ul>
                </div>
              </div>
              
              <div className="flex items-start space-x-3">
                <AlertTriangle className="h-5 w-5 text-yellow-500 mt-1" />
                <div>
                  <h4 className="font-semibold text-yellow-500">Important Notes</h4>
                  <ul className="list-disc list-inside text-gray-400 text-sm mt-2">
                    <li>Remove all power cables and accessories</li>
                    <li>Seal box with strong packing tape</li>
                    <li>Apply shipping label on top surface</li>
                    <li>Add "FRAGILE" and "THIS WAY UP" stickers</li>
                  </ul>
                </div>
              </div>
            </div>
            
            <button
              onClick={() => {
                generateShippingLabel();
                setStep(4);
              }}
              className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all"
            >
              Generate Shipping Label
            </button>
          </div>
        );
      
      case 4:
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-bold mb-4">Shipping Label</h3>
            
            <div className="bg-black/40 rounded-xl p-6 space-y-6">
              <div className="border-2 border-white/10 rounded-lg p-6 space-y-4">
                <div className="text-center">
                  <h4 className="font-bold text-xl mb-2">Euronet Trading Co. Ltd.</h4>
                  <p className="text-gray-400">275 New North Road</p>
                  <p className="text-gray-400">Islington</p>
                  <p className="text-gray-400">London, N1 7AA</p>
                  <p className="text-gray-400">United Kingdom</p>
                </div>
                
                <div className="border-t border-white/10 pt-4">
                  <p className="text-center font-mono text-xl">{shippingLabel}</p>
                </div>
              </div>
              
              <div className="flex items-start space-x-3">
                <AlertTriangle className="h-5 w-5 text-yellow-500 mt-1" />
                <div>
                  <h4 className="font-semibold text-yellow-500">Shipping Instructions</h4>
                  <ul className="list-disc list-inside text-gray-400 text-sm mt-2">
                    <li>Print label in high quality</li>
                    <li>Attach securely to package</li>
                    <li>Keep tracking number for reference</li>
                    <li>Ship within 5 business days</li>
                  </ul>
                </div>
              </div>
            </div>
            
            <button
              onClick={() => window.print()}
              className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all flex items-center justify-center space-x-2"
            >
              <Printer className="h-5 w-5" />
              <span>Print Shipping Label</span>
            </button>
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <AnimatePresence>
      <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          className="bg-gradient-to-b from-blue-900/40 to-black/40 rounded-2xl max-w-2xl w-full max-h-[90vh] overflow-y-auto border border-white/10"
        >
          <div className="sticky top-0 bg-black/90 backdrop-blur-sm p-6 border-b border-white/10">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Trade-In Process</h2>
              <button
                onClick={onClose}
                className="p-2 hover:bg-white/10 rounded-lg transition-colors"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            
            <div className="flex justify-between">
              {[1, 2, 3, 4].map((stepNumber) => (
                <div
                  key={stepNumber}
                  className={`flex-1 h-2 rounded-full mx-1 ${
                    stepNumber <= step ? 'bg-blue-600' : 'bg-white/10'
                  }`}
                />
              ))}
            </div>
          </div>

          <div className="p-6">
            <div className="mb-6">
              <div className="text-gray-400 mb-1">Selected Miner</div>
              <div className="text-lg font-semibold">{selectedMiner}</div>
              <div className="text-gray-400 mt-2">Estimated Value</div>
              <div className="text-2xl font-bold text-blue-500">€{estimatedValue.toFixed(2)}</div>
            </div>

            {renderStep()}
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default TradeInProcess;