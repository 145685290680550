import React, { useState } from 'react';
import { Calculator, AlertTriangle, Store } from 'lucide-react';
import TradeInProcess from './TradeInProcess';
import { MINER_MODELS } from '../data/minerModels';

const TradeInCalculator = () => {
  const [selectedModel, setSelectedModel] = useState('');
  const [condition, setCondition] = useState('excellent');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [showWarning, setShowWarning] = useState(false);
  const [showTradeInProcess, setShowTradeInProcess] = useState(false);
  
  const categories = ['all', ...new Set(MINER_MODELS.map(model => model.category))];
  
  const filteredModels = selectedCategory === 'all'
    ? MINER_MODELS
    : MINER_MODELS.filter(model => model.category === selectedCategory);
  
  const calculateTradeValue = () => {
    const baseValue = MINER_MODELS.find(m => m.id === selectedModel)?.value || 0;
    const multiplier = condition === 'excellent' ? 1 
      : condition === 'good' ? 0.7 
      : condition === 'fair' ? 0.4
      : condition === 'broken' ? 0.15
      : 0;
    return (baseValue * multiplier).toFixed(2);
  };

  const handleConditionChange = (value: string) => {
    setCondition(value);
    setShowWarning(value === 'broken');
  };

  const handleStartTradeIn = () => {
    setShowTradeInProcess(true);
  };

  const handleCloseTradeIn = () => {
    setShowTradeInProcess(false);
  };

  const selectedMinerName = MINER_MODELS.find(m => m.id === selectedModel)?.name || '';

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
      <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-2xl p-8 backdrop-blur-sm border border-white/10">
        <div className="flex items-center gap-4 mb-8">
          <Calculator className="h-8 w-8 text-blue-500" />
          <h2 className="text-3xl font-bold">Trade-In Calculator</h2>
        </div>

        <div className="bg-blue-600/20 border border-blue-500/50 rounded-lg p-4 mb-8">
          <div className="flex items-start gap-3">
            <Store className="h-5 w-5 text-blue-500 flex-shrink-0 mt-1" />
            <div>
              <p className="font-semibold text-blue-200">Store Credit Only</p>
              <p className="text-sm text-gray-300">
                Trade-in values are provided as store credit only, to be used towards the purchase of new mining equipment from Euronet Trading. We do not offer cash payments for trade-ins.
              </p>
            </div>
          </div>
        </div>
        
        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium mb-2">Miner Category</label>
              <select
                value={selectedCategory}
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                  setSelectedModel('');
                }}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="all">All Categories</option>
                {categories.slice(1).map((category) => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium mb-2">Select Your Miner</label>
              <select
                value={selectedModel}
                onChange={(e) => setSelectedModel(e.target.value)}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="">Choose a model</option>
                {filteredModels.map((model) => (
                  <option key={model.id} value={model.id}>{model.name}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium mb-2">Condition</label>
              <select
                value={condition}
                onChange={(e) => handleConditionChange(e.target.value)}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="excellent">Excellent - Like New</option>
                <option value="good">Good - Minor Wear</option>
                <option value="fair">Fair - Functional with Wear</option>
                <option value="broken">Not Functional / Broken</option>
              </select>
            </div>

            {showWarning && (
              <div className="bg-yellow-500/10 border border-yellow-500/50 rounded-lg p-4">
                <div className="flex items-start gap-3">
                  <AlertTriangle className="h-5 w-5 text-yellow-500 flex-shrink-0 mt-0.5" />
                  <div className="text-sm text-yellow-200">
                    <p className="font-semibold mb-1">Important Notice for Non-Functional Miners:</p>
                    <ul className="list-disc list-inside space-y-1">
                      <li>Miner must be in original condition</li>
                      <li>No signs of opening or tampering</li>
                      <li>All seals must be intact</li>
                      <li>Subject to verification upon receipt</li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
          
          <div className="bg-black/40 rounded-xl p-6 flex flex-col justify-center items-center">
            <p className="text-gray-400 mb-2">Estimated Store Credit Value</p>
            <div className="text-4xl font-bold mb-4">
              €{selectedModel ? calculateTradeValue() : '0.00'}
            </div>
            <button 
              className="bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all transform hover:scale-105 w-full disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 disabled:hover:bg-blue-600"
              disabled={!selectedModel}
              onClick={handleStartTradeIn}
            >
              Start Trade-In Process
            </button>
            <div className="text-sm text-gray-400 mt-4 text-center space-y-2">
              <p>Store credit can only be used towards new equipment purchases</p>
              <p>Final value subject to verification of condition and specifications</p>
            </div>
          </div>
        </div>
      </div>

      {showTradeInProcess && (
        <TradeInProcess
          selectedMiner={selectedMinerName}
          estimatedValue={parseFloat(calculateTradeValue())}
          onClose={handleCloseTradeIn}
        />
      )}
    </div>
  );
};

export default TradeInCalculator;