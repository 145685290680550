import React, { useRef } from 'react';
import { ArrowRightLeft, ArrowRight, PackageCheck, Truck, CreditCard, CircuitBoard } from 'lucide-react';
import { motion, useInView } from 'framer-motion';
import { Link } from 'react-router-dom';

const Hero = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(containerRef);

  const steps = [
    {
      icon: <ArrowRightLeft className="h-6 w-6" />,
      title: "Calculate Value",
      description: "Get instant credit estimate for your miner"
    },
    {
      icon: <PackageCheck className="h-6 w-6" />,
      title: "Ship Equipment",
      description: "Send your miner using our shipping label"
    },
    {
      icon: <CreditCard className="h-6 w-6" />,
      title: "Receive Credit",
      description: "Get store credit upon verification"
    },
    {
      icon: <Truck className="h-6 w-6" />,
      title: "New Hardware",
      description: "Use credit for latest mining equipment"
    }
  ];

  // Floating circuit board animation
  const FloatingCircuitBoard = () => (
    <motion.div
      className="absolute -z-10"
      animate={{
        y: [0, -20, 0],
        rotate: [0, 5, -5, 0],
      }}
      transition={{
        duration: 6,
        repeat: Infinity,
        ease: "easeInOut"
      }}
    >
      <CircuitBoard className="h-32 w-32 text-blue-500/10" />
    </motion.div>
  );

  return (
    <div className="relative overflow-hidden pt-20 pb-20" ref={containerRef}>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Background Circuit Boards */}
        <div className="absolute top-20 -left-16">
          <FloatingCircuitBoard />
        </div>
        <div className="absolute bottom-20 -right-16">
          <FloatingCircuitBoard />
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12 relative"
        >
          {/* Animated gradient border */}
          <div className="absolute inset-0 -z-10 bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-3xl animate-pulse" />
          
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="mb-6"
          >
            <motion.h1 
              className="text-4xl sm:text-6xl font-bold mb-6 relative"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <span className="bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent relative">
                Upgrade Your Mining Game
                {/* Animated highlight effect */}
                <motion.span
                  className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent -z-10"
                  animate={{
                    x: ["100%", "-100%"],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "linear",
                  }}
                />
              </span>
            </motion.h1>
          </motion.div>

          <motion.p 
            className="text-xl text-gray-400 mb-8 max-w-3xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            Trade in your old Antminer for the latest models. Get maximum value and upgrade to more efficient mining hardware.
          </motion.p>

          <motion.div 
            className="flex flex-col sm:flex-row gap-4 justify-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
          >
            <Link 
              to="/trade-in"
              className="group relative bg-blue-600 hover:bg-blue-700 px-8 py-4 rounded-lg text-lg font-semibold transition-all transform hover:scale-105 flex items-center justify-center overflow-hidden"
            >
              {/* Button highlight effect */}
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent"
                animate={{
                  x: ["100%", "-100%"],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "linear",
                }}
              />
              <span className="relative">Start Trade-In</span>
              <motion.span
                className="inline-block ml-2 relative"
                initial={{ x: 0 }}
                whileHover={{ x: 5 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <ArrowRight className="h-5 w-5" />
              </motion.span>
            </Link>
            <Link
              to="/products"
              className="group relative border border-blue-500 hover:bg-blue-500/10 px-8 py-4 rounded-lg text-lg font-semibold transition-all overflow-hidden flex items-center justify-center"
            >
              {/* Border glow effect */}
              <motion.div
                className="absolute inset-0 opacity-50 bg-blue-500 blur-xl group-hover:opacity-100 transition-opacity"
                style={{ mixBlendMode: 'overlay' }}
              />
              <span className="relative">View Catalog</span>
              <motion.span
                className="inline-block ml-2 relative"
                initial={{ x: 0 }}
                whileHover={{ x: 5 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <ArrowRight className="h-5 w-5" />
              </motion.span>
            </Link>
          </motion.div>
        </motion.div>

        <div className="mt-16 relative">
          {/* Animated connection lines background */}
          <motion.div
            className="absolute inset-0 -z-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: isInView ? 1 : 0 }}
            transition={{ duration: 1 }}
          >
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-900/20 via-transparent to-transparent" />
          </motion.div>

          <motion.h2 
            className="text-3xl font-bold text-center mb-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
          >
            How Trade-In Works
          </motion.h2>

          <div className="grid md:grid-cols-4 gap-8 relative">
            {steps.map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1 + index * 0.2 }}
                className="relative"
                whileHover={{ scale: 1.05 }}
              >
                <div className="bg-gradient-to-b from-blue-900/20 to-transparent p-6 rounded-xl border border-white/10 hover:border-blue-500/50 transition-all group">
                  <motion.div
                    className="bg-blue-500/10 p-4 rounded-full w-fit mb-4 group-hover:bg-blue-500/20 transition-colors"
                    whileHover={{ scale: 1.1, rotate: 360 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    {step.icon}
                  </motion.div>
                  <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                  <p className="text-gray-400">{step.description}</p>

                  {/* Card glow effect */}
                  <motion.div
                    className="absolute inset-0 -z-10 bg-blue-500/5 blur-xl opacity-0 group-hover:opacity-100 transition-opacity"
                    animate={{
                      scale: [1, 1.2, 1],
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  />
                </div>

                {index < steps.length - 1 && (
                  <motion.div 
                    className="hidden md:block absolute top-1/2 left-full w-full h-px bg-gradient-to-r from-blue-500 to-transparent -translate-y-1/2 z-10"
                    initial={{ scaleX: 0 }}
                    animate={{ scaleX: 1 }}
                    transition={{ delay: 1.5 + index * 0.2, duration: 0.5 }}
                  />
                )}
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Enhanced background effects */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-900/20 via-black to-black" />
        <motion.div 
          className="absolute inset-0 hero-pattern opacity-20"
          animate={{ 
            backgroundPosition: ["0% 0%", "100% 100%"],
          }}
          transition={{ 
            duration: 20,
            ease: "linear",
            repeat: Infinity,
            repeatType: "reverse"
          }}
        />
      </div>

      {/* Animated gradient orbs */}
      <motion.div
        className="absolute -top-1/2 -right-1/2 w-full h-full bg-blue-500/5 rounded-full blur-3xl"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          repeatType: "reverse"
        }}
      />
      <motion.div
        className="absolute -bottom-1/2 -left-1/2 w-full h-full bg-purple-500/5 rounded-full blur-3xl"
        animate={{
          scale: [1.2, 1, 1.2],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          repeatType: "reverse",
          delay: 1
        }}
      />
    </div>
  );
};

export default Hero;