import React from 'react';
import { CircuitBoard, Mail, Phone, MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-black/90 border-t border-white/10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid md:grid-cols-4 gap-8">
          <div>
            <Link to="/" className="flex items-center mb-4">
              <CircuitBoard className="h-8 w-8 text-blue-500" />
              <span className="ml-2 text-xl font-bold">Euronet Trading</span>
            </Link>
            <p className="text-gray-400">
              Your trusted partner in crypto mining hardware trading and upgrades.
            </p>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="text-gray-400 hover:text-blue-500">Home</Link></li>
              <li><Link to="/trade-in" className="text-gray-400 hover:text-blue-500">Trade-In</Link></li>
              <li><Link to="/products" className="text-gray-400 hover:text-blue-500">Products</Link></li>
              <li><Link to="/about" className="text-gray-400 hover:text-blue-500">About Us</Link></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact</h3>
            <ul className="space-y-2">
              <li className="flex items-center text-gray-400">
                <Mail className="h-5 w-5 mr-2" />
                info@euronet-trading.co.uk
              </li>
              <li className="flex items-center text-gray-400">
                <MapPin className="h-5 w-5 mr-2" />
                275 New North Road, London N1 7AA, UK
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/terms" className="text-gray-400 hover:text-blue-500">Terms of Service</Link></li>
              <li><Link to="/privacy" className="text-gray-400 hover:text-blue-500">Privacy Policy</Link></li>
              <li><Link to="/cookies" className="text-gray-400 hover:text-blue-500">Cookie Policy</Link></li>
            </ul>
          </div>
        </div>
        
        <div className="border-t border-white/10 mt-12 pt-8 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Euronet Trading. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;