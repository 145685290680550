import React from 'react';
import { motion } from 'framer-motion';
import { X, Package, User, MapPin, Truck, CreditCard } from 'lucide-react';
import { formatPrice } from '../../utils/priceFormatter';

interface OrderDetailsProps {
  order: any;
  onClose: () => void;
  onStatusUpdate: (orderId: string, status: string) => void;
}

const OrderDetails = ({ order, onClose, onStatusUpdate }: OrderDetailsProps) => {
  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-gradient-to-b from-blue-900/40 to-black/40 rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto border border-white/10"
      >
        <div className="sticky top-0 bg-black/90 backdrop-blur-sm p-6 border-b border-white/10 flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold">Order Details</h2>
            <p className="text-gray-400">Order #{order.order_number}</p>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-lg transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6 space-y-8">
          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-4 flex items-center">
                  <User className="h-5 w-5 mr-2 text-blue-500" />
                  Customer Information
                </h3>
                <div className="bg-black/40 rounded-lg p-4 space-y-2">
                  <p><span className="text-gray-400">Name:</span> {order.customer_name}</p>
                  <p><span className="text-gray-400">Email:</span> {order.customer_email}</p>
                  <p><span className="text-gray-400">Phone:</span> {order.customer_phone}</p>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-4 flex items-center">
                  <MapPin className="h-5 w-5 mr-2 text-blue-500" />
                  Shipping Address
                </h3>
                <div className="bg-black/40 rounded-lg p-4">
                  <p className="whitespace-pre-wrap">{order.customer_address}</p>
                </div>
              </div>
            </div>

            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-4 flex items-center">
                  <CreditCard className="h-5 w-5 mr-2 text-blue-500" />
                  Payment Information
                </h3>
                <div className="bg-black/40 rounded-lg p-4 space-y-2">
                  <p><span className="text-gray-400">Method:</span> {order.payment_method}</p>
                  <p><span className="text-gray-400">Status:</span> 
                    <span className={`ml-2 px-2 py-1 rounded-full text-sm ${
                      order.payment_status === 'paid' ? 'bg-green-500/20 text-green-400' :
                      order.payment_status === 'pending' ? 'bg-yellow-500/20 text-yellow-400' :
                      'bg-red-500/20 text-red-400'
                    }`}>
                      {order.payment_status}
                    </span>
                  </p>
                  {order.transaction_id && (
                    <p><span className="text-gray-400">Transaction ID:</span> {order.transaction_id}</p>
                  )}
                </div>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-4 flex items-center">
                  <Truck className="h-5 w-5 mr-2 text-blue-500" />
                  Shipping Information
                </h3>
                <div className="bg-black/40 rounded-lg p-4 space-y-2">
                  <p><span className="text-gray-400">Method:</span> {order.shipping_method}</p>
                  <p><span className="text-gray-400">Status:</span> {order.shipping_status}</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4 flex items-center">
              <Package className="h-5 w-5 mr-2 text-blue-500" />
              Order Items
            </h3>
            <div className="bg-black/40 rounded-lg overflow-hidden">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-white/10">
                    <th className="text-left p-4">Product</th>
                    <th className="text-left p-4">Quantity</th>
                    <th className="text-left p-4">Price</th>
                    <th className="text-left p-4">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {order.items?.map((item: any) => (
                    <tr key={item.id} className="border-b border-white/10">
                      <td className="p-4">
                        <div>
                          <p className="font-semibold">{item.product.name}</p>
                          <p className="text-sm text-gray-400">{item.product.hashrate}</p>
                        </div>
                      </td>
                      <td className="p-4">{item.quantity}</td>
                      <td className="p-4">{formatPrice(item.price_per_unit)}</td>
                      <td className="p-4">{formatPrice(item.total_amount)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="border-t border-white/10 bg-black/20">
                    <td colSpan={3} className="p-4 text-right">Subtotal:</td>
                    <td className="p-4 font-semibold">{formatPrice(order.subtotal)}</td>
                  </tr>
                  <tr className="border-t border-white/10 bg-black/20">
                    <td colSpan={3} className="p-4 text-right">Shipping:</td>
                    <td className="p-4 font-semibold">{formatPrice(order.shipping_cost)}</td>
                  </tr>
                  <tr className="border-t border-white/10 bg-black/20">
                    <td colSpan={3} className="p-4 text-right font-bold">Total:</td>
                    <td className="p-4 font-bold text-xl">{formatPrice(order.total_amount)}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>

          {order.notes && (
            <div>
              <h3 className="text-lg font-semibold mb-4">Order Notes</h3>
              <div className="bg-black/40 rounded-lg p-4">
                <p className="text-gray-400 whitespace-pre-wrap">{order.notes}</p>
              </div>
            </div>
          )}

          {order.payment_status === 'pending' && (
            <div className="flex justify-end gap-4">
              <button
                onClick={() => onStatusUpdate(order.id, 'cancelled')}
                className="px-6 py-3 rounded-lg border border-red-500 text-red-500 hover:bg-red-500/10 transition-colors"
              >
                Cancel Order
              </button>
              <button
                onClick={() => onStatusUpdate(order.id, 'paid')}
                className="px-6 py-3 rounded-lg bg-green-600 hover:bg-green-700 transition-colors"
              >
                Mark as Paid
              </button>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default OrderDetails;