import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';
import { User, UserFormData } from '../data/types';

export const useUsers = () => {
  const queryClient = useQueryClient();

  const getUsers = async () => {
    const { data: users, error } = await supabase
      .from('users')
      .select(`
        *,
        admin_users!left(user_id)
      `)
      .order('created_at', { ascending: false });

    if (error) throw error;

    return users.map(user => ({
      ...user,
      is_admin: !!user.admin_users
    })) as User[];
  };

  const updateUser = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: UserFormData }) => {
      // Update user profile
      const { error: userError } = await supabase
        .from('users')
        .update({
          full_name: data.full_name,
          phone: data.phone,
          company: data.company,
          billing_address: data.billing_address,
          shipping_address: data.shipping_address
        })
        .eq('id', id);

      if (userError) throw userError;

      // Handle admin status
      if (data.is_admin) {
        await supabase
          .from('admin_users')
          .upsert({ user_id: id })
          .eq('user_id', id);
      } else {
        await supabase
          .from('admin_users')
          .delete()
          .eq('user_id', id);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    }
  });

  const deleteUser = useMutation({
    mutationFn: async (id: string) => {
      // Delete from admin_users first (if exists)
      await supabase
        .from('admin_users')
        .delete()
        .eq('user_id', id);

      // Then delete the user
      const { error } = await supabase
        .from('users')
        .delete()
        .eq('id', id);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    }
  });

  return {
    users: useQuery({
      queryKey: ['users'],
      queryFn: getUsers
    }),
    updateUser,
    deleteUser
  };
};