import React, { useState, useEffect } from 'react';
import { Calculator, Info, TrendingUp, DollarSign } from 'lucide-react';

const HostingCalculator = () => {
  const [minerModel, setMinerModel] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [months, setMonths] = useState(6);
  const [btcPrice, setBtcPrice] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBTCPrice = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=eur');
        const data = await response.json();
        setBtcPrice(data.bitcoin.eur);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching BTC price:', error);
        setLoading(false);
      }
    };

    fetchBTCPrice();
    const interval = setInterval(fetchBTCPrice, 60000);
    return () => clearInterval(interval);
  }, []);

  const minerSpecs = {
    's19_pro_110th': { 
      name: 'S19 Pro (110Th)', 
      power: 3250,
      hashrate: 110,
      algorithm: 'SHA-256'
    },
    's19j_pro_104th': { 
      name: 'S19j Pro (104Th)', 
      power: 3068,
      hashrate: 104,
      algorithm: 'SHA-256'
    },
    's19_xp_140th': { 
      name: 'S19 XP (140Th)', 
      power: 3010,
      hashrate: 140,
      algorithm: 'SHA-256'
    },
    's21_200th': { 
      name: 'S21 (200Th)', 
      power: 6200,
      hashrate: 200,
      algorithm: 'SHA-256'
    },
    's21_195th': {
      name: 'S21 (195Th)',
      power: 5887,
      hashrate: 195,
      algorithm: 'SHA-256'
    },
    's21_188th': {
      name: 'S21 (188Th)',
      power: 5674,
      hashrate: 188,
      algorithm: 'SHA-256'
    },
    's21_pro_234th': {
      name: 'S21 Pro (234Th)',
      power: 7450,
      hashrate: 234,
      algorithm: 'SHA-256'
    },
    's21_plus_216th': {
      name: 'S21+ (216Th)',
      power: 6480,
      hashrate: 216,
      algorithm: 'SHA-256'
    },
    's19pro_hyd_198th': {
      name: 'S19 Pro+ Hyd (198Th)',
      power: 5445,
      hashrate: 198,
      algorithm: 'SHA-256'
    },
    's19pro_hyd_191th': {
      name: 'S19 Pro+ Hyd (191Th)',
      power: 5252,
      hashrate: 191,
      algorithm: 'SHA-256'
    },
    's19e_xp_240th': {
      name: 'S19E XP (240Th)',
      power: 6480,
      hashrate: 240,
      algorithm: 'SHA-256'
    },
    's19e_xp_251th': {
      name: 'S19E XP (251Th)',
      power: 6776,
      hashrate: 251,
      algorithm: 'SHA-256'
    },
    's21_hyd_335th': {
      name: 'S21 Hydro (335Th)',
      power: 8710,
      hashrate: 335,
      algorithm: 'SHA-256'
    },
    's21xp_hyd_473th': {
      name: 'S21XP Hyd (473Th)',
      power: 11825,
      hashrate: 473,
      algorithm: 'SHA-256'
    },
    's21exp_hyd_860th': {
      name: 'S21EXP Hyd (860Th)',
      power: 21500,
      hashrate: 860,
      algorithm: 'SHA-256'
    }
  };

  const calculatePowerCost = () => {
    if (!minerModel) return 0;
    const powerConsumption = minerSpecs[minerModel as keyof typeof minerSpecs].power;
    const kwhPerMonth = (powerConsumption * 24 * 30) / 1000;
    return kwhPerMonth * 0.045 * quantity;
  };

  const calculateInsuranceCost = () => {
    return 35 * quantity;
  };

  const calculateTotalCost = () => {
    return (calculatePowerCost() + calculateInsuranceCost()) * months;
  };

  const calculateBTCRevenue = () => {
    if (!minerModel || !btcPrice) return 0;
    const specs = minerSpecs[minerModel as keyof typeof minerSpecs];
    const hashrate = specs.hashrate;
    const networkHashrate = 500000000; // Example network hashrate in TH/s
    const blocksPerDay = 144;
    const blockReward = 6.25;
    
    const dailyBTC = (hashrate / networkHashrate) * blocksPerDay * blockReward;
    const monthlyBTC = dailyBTC * 30 * quantity;
    return monthlyBTC * btcPrice * months;
  };

  const calculateProfit = () => {
    return calculateBTCRevenue() - calculateTotalCost();
  };

  return (
    <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-2xl p-8">
      <div className="flex items-center gap-4 mb-8">
        <Calculator className="h-8 w-8 text-blue-500" />
        <h2 className="text-2xl font-bold">Hosting Calculator</h2>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium mb-2">Select Miner Model</label>
            <select
              value={minerModel}
              onChange={(e) => setMinerModel(e.target.value)}
              className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="">Choose a model</option>
              {Object.entries(minerSpecs).map(([id, specs]) => (
                <option key={id} value={id}>{specs.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">Quantity</label>
            <input
              type="number"
              min="1"
              value={quantity}
              onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
              className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">Hosting Period (months)</label>
            <input
              type="number"
              min="6"
              value={months}
              onChange={(e) => setMonths(Math.max(6, parseInt(e.target.value) || 6))}
              className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          {minerModel && (
            <div className="bg-black/40 rounded-lg p-4">
              <h3 className="font-semibold mb-2">Selected Miner Specs</h3>
              <div className="space-y-2 text-gray-400">
                <p>Hashrate: {minerSpecs[minerModel as keyof typeof minerSpecs].hashrate} TH/s</p>
                <p>Power Consumption: {minerSpecs[minerModel as keyof typeof minerSpecs].power}W</p>
                <p>Algorithm: {minerSpecs[minerModel as keyof typeof minerSpecs].algorithm}</p>
              </div>
            </div>
          )}
        </div>

        <div className="space-y-6">
          <div className="bg-black/40 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-4">Monthly Costs</h3>
            <div className="space-y-4">
              <div className="flex justify-between">
                <span>Power Cost (€0.045/kWh):</span>
                <span className="font-semibold">€{calculatePowerCost().toFixed(2)}</span>
              </div>
              <div className="flex justify-between">
                <span>Insurance:</span>
                <span className="font-semibold">€{calculateInsuranceCost().toFixed(2)}</span>
              </div>
              <div className="border-t border-white/10 pt-4">
                <div className="flex justify-between">
                  <span>Total Monthly Cost:</span>
                  <span className="font-semibold">€{(calculatePowerCost() + calculateInsuranceCost()).toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>

          {!loading && (
            <div className="bg-black/40 rounded-lg p-6">
              <h3 className="text-xl font-bold mb-4">Estimated Returns</h3>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span>Current BTC Price:</span>
                  <span className="font-semibold">€{btcPrice.toLocaleString()}</span>
                </div>
                <div className="flex justify-between">
                  <span>Estimated Revenue ({months} months):</span>
                  <span className="font-semibold text-green-500">€{calculateBTCRevenue().toFixed(2)}</span>
                </div>
                <div className="flex justify-between">
                  <span>Total Costs ({months} months):</span>
                  <span className="font-semibold text-red-500">€{calculateTotalCost().toFixed(2)}</span>
                </div>
                <div className="border-t border-white/10 pt-4">
                  <div className="flex justify-between">
                    <span>Estimated Profit:</span>
                    <span className={`font-semibold ${calculateProfit() >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                      €{calculateProfit().toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="bg-blue-500/10 rounded-lg p-4 flex items-start gap-3">
            <Info className="h-5 w-5 text-blue-500 flex-shrink-0 mt-1" />
            <p className="text-sm text-gray-400">
              Calculations are estimates based on current network difficulty and BTC price. 
              Actual results may vary due to market conditions and network changes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostingCalculator;