import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Cookie, ChevronDown, ChevronUp } from 'lucide-react';
import { Link } from 'react-router-dom';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false
  });

  useEffect(() => {
    // Check if user has already made a choice
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptAll = () => {
    const consentData = {
      necessary: true,
      analytics: true,
      marketing: true,
      timestamp: new Date().toISOString()
    };
    
    setPreferences(consentData);
    localStorage.setItem('cookieConsent', JSON.stringify(consentData));
    setIsVisible(false);
  };

  const handleSavePreferences = () => {
    const consentData = {
      ...preferences,
      timestamp: new Date().toISOString()
    };
    
    localStorage.setItem('cookieConsent', JSON.stringify(consentData));
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        className="fixed bottom-0 left-0 right-0 z-50 p-4"
      >
        <div className="max-w-7xl mx-auto">
          <div className="bg-gradient-to-r from-blue-900/90 to-black/90 backdrop-blur-lg rounded-xl border border-white/10 shadow-2xl">
            <div className="p-6">
              <div className="flex items-start justify-between mb-4">
                <div className="flex items-center">
                  <Cookie className="h-6 w-6 text-blue-500 mr-2" />
                  <h3 className="text-xl font-bold">Cookie Settings</h3>
                </div>
                <button
                  onClick={() => setShowDetails(!showDetails)}
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  {showDetails ? (
                    <ChevronUp className="h-5 w-5" />
                  ) : (
                    <ChevronDown className="h-5 w-5" />
                  )}
                </button>
              </div>

              <p className="text-gray-400 mb-4">
                We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. 
                Read our <Link to="/cookies" className="text-blue-500 hover:text-blue-400">Cookie Policy</Link> to learn more.
              </p>

              {showDetails && (
                <div className="space-y-4 mb-6">
                  <div className="flex items-center justify-between p-4 bg-black/40 rounded-lg">
                    <div>
                      <h4 className="font-semibold">Necessary Cookies</h4>
                      <p className="text-sm text-gray-400">Required for the website to function properly</p>
                    </div>
                    <input
                      type="checkbox"
                      checked={preferences.necessary}
                      disabled
                      className="rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                    />
                  </div>

                  <div className="flex items-center justify-between p-4 bg-black/40 rounded-lg">
                    <div>
                      <h4 className="font-semibold">Analytics Cookies</h4>
                      <p className="text-sm text-gray-400">Help us improve our website by collecting usage information</p>
                    </div>
                    <input
                      type="checkbox"
                      checked={preferences.analytics}
                      onChange={(e) => setPreferences({ ...preferences, analytics: e.target.checked })}
                      className="rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                    />
                  </div>

                  <div className="flex items-center justify-between p-4 bg-black/40 rounded-lg">
                    <div>
                      <h4 className="font-semibold">Marketing Cookies</h4>
                      <p className="text-sm text-gray-400">Used to deliver personalized advertisements</p>
                    </div>
                    <input
                      type="checkbox"
                      checked={preferences.marketing}
                      onChange={(e) => setPreferences({ ...preferences, marketing: e.target.checked })}
                      className="rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                    />
                  </div>
                </div>
              )}

              <div className="flex flex-col sm:flex-row gap-4">
                <button
                  onClick={handleAcceptAll}
                  className="flex-1 bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-colors text-center"
                >
                  Accept All
                </button>
                <button
                  onClick={handleSavePreferences}
                  className="flex-1 border border-blue-500 hover:bg-blue-500/10 px-6 py-3 rounded-lg transition-colors text-center"
                >
                  Save Preferences
                </button>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CookieConsent;