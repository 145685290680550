import React from 'react';
import { CheckCircle2, Package, User, MapPin, AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { formatPrice } from '../utils/priceFormatter';

interface OrderConfirmationProps {
  orderNumber: string;
  customerInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address: string;
    city: string;
    country: string;
    postalCode: string;
  };
  items: any[];
  total: number;
}

const OrderConfirmation = ({ orderNumber, customerInfo, items, total }: OrderConfirmationProps) => {
  return (
    <div className="max-w-3xl mx-auto">
      <div className="text-center mb-8">
        <div className="inline-flex items-center justify-center w-16 h-16 bg-blue-500/20 rounded-full mb-4">
          <CheckCircle2 className="h-8 w-8 text-blue-500" />
        </div>
        <h2 className="text-2xl font-bold mb-2">Order Submitted Successfully!</h2>
        <p className="text-gray-400">
          Your order has been submitted and is pending payment confirmation.
        </p>
      </div>

      <div className="bg-blue-500/10 border border-blue-500/50 rounded-lg p-4 mb-6">
        <div className="flex items-start gap-3">
          <AlertCircle className="h-5 w-5 text-blue-500 flex-shrink-0 mt-1" />
          <div>
            <h3 className="font-semibold text-blue-200 mb-2">Payment Instructions</h3>
            <p className="text-sm text-gray-300">
              Please complete your payment using the provided payment details. Your order will be processed once payment is confirmed.
              The total amount to be paid is: <span className="font-bold">{formatPrice(total)}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="space-y-6">
        <div className="bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl border border-white/10 p-6">
          <div className="flex items-center gap-3 mb-4">
            <Package className="h-5 w-5 text-blue-500" />
            <h3 className="text-lg font-semibold">Order Details</h3>
          </div>
          
          <div className="space-y-4">
            <div className="flex justify-between text-sm">
              <span className="text-gray-400">Order Number:</span>
              <span className="font-mono">{orderNumber}</span>
            </div>
            
            <div className="flex justify-between text-sm">
              <span className="text-gray-400">Payment Status:</span>
              <span className="bg-yellow-500/20 text-yellow-400 px-2 py-1 rounded-full text-xs">
                Pending Payment
              </span>
            </div>
            
            <div className="border-t border-white/10 pt-4 space-y-4">
              {items.map((item) => (
                <div key={item.id} className="flex justify-between">
                  <div>
                    <p className="font-semibold">{item.name}</p>
                    <p className="text-sm text-gray-400">
                      Quantity: {item.quantity} • {item.shippingOption === 'ddp' ? 'DDP Shipping' : 'Standard Shipping'}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="font-semibold">{formatPrice(item.price * item.quantity)}</p>
                    <p className="text-sm text-gray-400">
                      {formatPrice(item.price)} each
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="border-t border-white/10 pt-4">
              <div className="flex justify-between font-bold">
                <span>Total Amount Due</span>
                <span>{formatPrice(total)}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl border border-white/10 p-6">
          <div className="flex items-center gap-3 mb-4">
            <User className="h-5 w-5 text-blue-500" />
            <h3 className="text-lg font-semibold">Customer Information</h3>
          </div>
          
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <p className="text-gray-400 text-sm mb-1">Contact Information</p>
              <p className="font-semibold">{customerInfo.firstName} {customerInfo.lastName}</p>
              <p>{customerInfo.email}</p>
              <p>{customerInfo.phone}</p>
            </div>
            
            <div>
              <p className="text-gray-400 text-sm mb-1">Shipping Address</p>
              <p className="font-semibold">{customerInfo.firstName} {customerInfo.lastName}</p>
              <p>{customerInfo.address}</p>
              <p>{customerInfo.city}, {customerInfo.postalCode}</p>
              <p>{customerInfo.country}</p>
            </div>
          </div>
        </div>

        <div className="text-center space-y-4">
          <p className="text-gray-400">
            You will receive an email confirmation with payment instructions and order details.
          </p>
          
          <Link
            to="/dashboard"
            className="inline-flex items-center bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all"
          >
            View Order Status
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;