import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { X, Copy, Check, ExternalLink } from 'lucide-react';
import { createOrder } from '../lib/orders';
import { useCartStore } from '../stores/cartStore';
import { formatPrice } from '../utils/priceFormatter';

interface PaymentModalProps {
  paymentMethod: 'crypto' | 'bank';
  amount: number;
  onClose: () => void;
  onComplete: (orderId: string) => void;
  customerInfo: any;
  items: any[];
}

const PaymentModal: React.FC<PaymentModalProps> = ({ 
  paymentMethod, 
  amount, 
  onClose, 
  onComplete, 
  customerInfo, 
  items 
}) => {
  const [copied, setCopied] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const clearCart = useCartStore(state => state.clearCart);

  const cryptoAddresses = {
    BTC: 'bc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh',
    ETH: '0x71C7656EC7ab88b098defB751B7401B5f6d8976F',
    USDT: 'TXk8rQSAvZBqb8ESgNBCHh41WYwQyonx5m'
  };

  const bankDetails = {
    bankName: 'Deutsche Bank',
    accountName: 'Euronet Trading GmbH',
    iban: 'DE89 3704 0044 0532 0130 00',
    bic: 'DEUTDEBBXXX',
    reference: `ORD-${Date.now().toString(36).toUpperCase()}`
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleCreateOrder = async () => {
    setProcessing(true);
    setError('');

    try {
      const order = await createOrder({
        customerInfo,
        paymentMethod,
        transactionId: bankDetails.reference,
        items,
        total: amount
      });

      if (order) {
        clearCart();
        onComplete(order.order_number);
        onClose();
      } else {
        throw new Error('Failed to create order');
      }
    } catch (err: any) {
      console.error('Error creating order:', err);
      setError(err.message || 'Failed to create order');
      setProcessing(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-gradient-to-b from-blue-900/40 to-black/40 rounded-2xl max-w-2xl w-full border border-white/10"
      >
        <div className="p-6 border-b border-white/10 flex justify-between items-center">
          <h2 className="text-2xl font-bold">
            {paymentMethod === 'crypto' ? 'Cryptocurrency Payment' : 'Bank Transfer'}
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-lg transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6">
          <div className="bg-black/40 rounded-xl p-6 mb-6">
            <div className="text-center">
              <div className="text-gray-400">Amount to Pay</div>
              <div className="text-3xl font-bold text-blue-500">{formatPrice(amount)}</div>
            </div>
          </div>

          {error && (
            <div className="bg-red-500/10 border border-red-500/50 rounded-lg p-4 mb-6">
              <p className="text-red-400 text-sm">{error}</p>
            </div>
          )}

          {paymentMethod === 'crypto' ? (
            <div className="space-y-6">
              {Object.entries(cryptoAddresses).map(([currency, address]) => (
                <div key={currency} className="bg-black/40 rounded-lg p-4">
                  <div className="text-sm text-gray-400 mb-2">{currency} Address</div>
                  <div className="flex items-center gap-2">
                    <code className="flex-grow font-mono bg-black/40 p-2 rounded">{address}</code>
                    <button
                      onClick={() => handleCopy(address)}
                      className="p-2 hover:bg-white/10 rounded-lg transition-colors"
                    >
                      {copied ? (
                        <Check className="h-5 w-5 text-green-500" />
                      ) : (
                        <Copy className="h-5 w-5" />
                      )}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="space-y-4">
              {Object.entries(bankDetails).map(([key, value]) => (
                <div key={key} className="bg-black/40 rounded-lg p-4">
                  <div className="text-sm text-gray-400 mb-2">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </div>
                  <div className="flex items-center gap-2">
                    <code className="flex-grow font-mono bg-black/40 p-2 rounded">{value}</code>
                    <button
                      onClick={() => handleCopy(value)}
                      className="p-2 hover:bg-white/10 rounded-lg transition-colors"
                    >
                      {copied ? (
                        <Check className="h-5 w-5 text-green-500" />
                      ) : (
                        <Copy className="h-5 w-5" />
                      )}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="mt-6 text-center space-y-4">
            <p className="text-gray-400">
              After payment, please send your transaction details to:
            </p>
            <a
              href="mailto:payments@euronet-trading.co.uk"
              className="text-blue-500 hover:text-blue-400 flex items-center justify-center gap-1"
            >
              payments@euronet-trading.co.uk
              <ExternalLink className="h-4 w-4" />
            </a>

            <button
              onClick={handleCreateOrder}
              disabled={processing}
              className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {processing ? (
                <div className="flex items-center justify-center gap-2">
                  <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                  <span>Processing...</span>
                </div>
              ) : (
                'Complete Order'
              )}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default PaymentModal;