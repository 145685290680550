import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Wrench, CircuitBoard, Cpu, Battery, ClipboardCheck, ArrowRight, AlertTriangle } from 'lucide-react';
import RepairRequestForm from '../components/RepairRequestForm';

const RepairPage = () => {
  const [showRequestForm, setShowRequestForm] = useState(false);

  const repairServices = [
    {
      icon: <CircuitBoard className="h-8 w-8" />,
      title: "Hashboard Repair",
      description: "Expert repair of Bitmain hashboards, including chip replacement and PCB repair",
      price: "Starting from €150"
    },
    {
      icon: <Cpu className="h-8 w-8" />,
      title: "Control Board Repair",
      description: "Control board diagnostics and component-level repair services",
      price: "Starting from €100"
    },
    {
      icon: <Battery className="h-8 w-8" />,
      title: "Power Supply Repair",
      description: "PSU repair and maintenance for all Antminer models",
      price: "Starting from €80"
    }
  ];

  const handleSubmit = (data: any) => {
    console.log('Repair request submitted:', data);
    setShowRequestForm(false);
  };

  return (
    <div className="pt-24 pb-16">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">Professional Repair Center</h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Expert repair services for Bitmain mining hardware
          </p>
        </div>

        <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-2xl p-8 mb-16">
          <div className="flex items-start gap-3 mb-8">
            <AlertTriangle className="h-6 w-6 text-yellow-500 flex-shrink-0 mt-1" />
            <div>
              <h3 className="font-semibold text-lg mb-2">Important Information</h3>
              <ul className="list-disc list-inside space-y-2 text-gray-300">
                <li>Diagnostic fee of €25 applies to all repair requests</li>
                <li>Diagnostic fee must be paid regardless of repair acceptance</li>
                <li>Shipping costs are the responsibility of the client</li>
                <li>Final repair cost will be quoted after diagnostics</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {repairServices.map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
              className="bg-gradient-to-b from-blue-900/20 to-transparent p-6 rounded-xl border border-white/10 hover:border-blue-500/50 transition-all"
            >
              <div className="bg-blue-500/10 p-4 rounded-full w-fit mb-4">
                {service.icon}
              </div>
              <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
              <p className="text-gray-400 mb-4">{service.description}</p>
              <p className="text-lg font-semibold text-blue-500">{service.price}</p>
            </motion.div>
          ))}
        </div>

        <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-2xl p-8 mb-16">
          <h2 className="text-2xl font-bold mb-6">Our Repair Process</h2>
          <div className="grid md:grid-cols-4 gap-8">
            {[
              {
                icon: <ClipboardCheck className="h-6 w-6" />,
                title: "Submit Request",
                description: "Fill out our repair request form with your device details"
              },
              {
                icon: <Wrench className="h-6 w-6" />,
                title: "Diagnostics",
                description: "We'll diagnose the issue and provide a detailed quote"
              },
              {
                icon: <CircuitBoard className="h-6 w-6" />,
                title: "Repair",
                description: "Expert technicians repair your hardware"
              },
              {
                icon: <ArrowRight className="h-6 w-6" />,
                title: "Return",
                description: "Your repaired device is thoroughly tested and shipped back"
              }
            ].map((step, index) => (
              <div key={index} className="text-center">
                <div className="bg-blue-500/10 p-4 rounded-full w-fit mx-auto mb-4">
                  {step.icon}
                </div>
                <h3 className="text-lg font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-400">{step.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="text-center">
          <button
            onClick={() => setShowRequestForm(true)}
            className="inline-flex items-center bg-blue-600 hover:bg-blue-700 px-8 py-4 rounded-lg text-lg font-semibold transition-all transform hover:scale-105"
          >
            Start Repair Request
            <ArrowRight className="ml-2 h-5 w-5" />
          </button>
        </div>

        {showRequestForm && (
          <RepairRequestForm 
            onSubmit={handleSubmit} 
            onClose={() => setShowRequestForm(false)} 
          />
        )}
      </motion.div>
    </div>
  );
};

export default RepairPage;