import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Product } from '../data/types';

interface CartItem {
  id: number;
  name: string;
  image: string;
  price: number;
  quantity: number;
  shippingOption: 'standard' | 'ddp';
}

interface CartStore {
  items: CartItem[];
  addItem: (product: Product, shippingOption: 'standard' | 'ddp') => void;
  removeItem: (productId: number) => void;
  updateQuantity: (productId: number, quantity: number) => void;
  updateShippingOption: (productId: number, option: 'standard' | 'ddp') => void;
  clearCart: () => void;
  getSubtotal: () => number;
  getShippingTotal: () => number;
  getDDPTotal: () => number;
  getTotal: () => number;
}

export const useCartStore = create<CartStore>()(
  persist(
    (set, get) => ({
      items: [],
      
      addItem: (product, shippingOption) => {
        set((state) => {
          const existingItem = state.items.find(item => item.id === product.id);
          
          if (existingItem) {
            return {
              items: state.items.map(item =>
                item.id === product.id
                  ? { ...item, quantity: item.quantity + 1 }
                  : item
              )
            };
          }
          
          return {
            items: [...state.items, {
              id: product.id,
              name: product.name,
              image: product.image,
              price: product.price,
              quantity: 1,
              shippingOption
            }]
          };
        });
      },
      
      removeItem: (productId) => {
        set((state) => ({
          items: state.items.filter(item => item.id !== productId)
        }));
      },
      
      updateQuantity: (productId, quantity) => {
        if (quantity < 1) return;
        
        set((state) => ({
          items: state.items.map(item =>
            item.id === productId
              ? { ...item, quantity }
              : item
          )
        }));
      },
      
      updateShippingOption: (productId, option) => {
        set((state) => ({
          items: state.items.map(item =>
            item.id === productId
              ? { ...item, shippingOption: option }
              : item
          )
        }));
      },
      
      clearCart: () => {
        set({ items: [] });
      },
      
      getSubtotal: () => {
        const { items } = get();
        return items.reduce((total, item) => total + (item.price * item.quantity), 0);
      },
      
      getShippingTotal: () => {
        const { items } = get();
        return items.reduce((total, item) => {
          if (item.shippingOption === 'standard') {
            return total + (135 * item.quantity); // Standard shipping cost per item
          }
          return total;
        }, 0);
      },

      getDDPTotal: () => {
        const { items, getSubtotal } = get();
        const subtotal = getSubtotal();
        return items.some(item => item.shippingOption === 'ddp') ? subtotal * 0.18 : 0; // 18% DDP fee
      },
      
      getTotal: () => {
        const { getSubtotal, getShippingTotal, getDDPTotal } = get();
        return getSubtotal() + getShippingTotal() + getDDPTotal();
      }
    }),
    {
      name: 'cart-storage',
      version: 1
    }
  )
);