import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle2, ArrowRight, ShieldCheck, Clock, Truck, Store, AlertTriangle } from 'lucide-react';
import TradeInCalculator from '../components/TradeInCalculator';

const steps = [
  {
    icon: <CheckCircle2 className="h-8 w-8" />,
    title: "Get Your Quote",
    description: "Calculate your store credit value instantly"
  },
  {
    icon: <ShieldCheck className="h-8 w-8" />,
    title: "Submit Details",
    description: "Provide equipment condition and information"
  },
  {
    icon: <Clock className="h-8 w-8" />,
    title: "Verification",
    description: "Our team reviews and confirms trade-in credit"
  },
  {
    icon: <Store className="h-8 w-8" />,
    title: "Upgrade Equipment",
    description: "Use your credit for new mining hardware"
  }
];

const TradeInPage = () => {
  return (
    <div className="pt-24 pb-16">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold mb-4">Trade In Your Mining Hardware</h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Upgrade your mining operation with our store credit trade-in program
          </p>
        </div>

        <div className="bg-blue-600/20 border border-blue-500/50 rounded-lg p-6 mb-16 max-w-3xl mx-auto">
          <div className="flex items-start gap-4">
            <AlertTriangle className="h-6 w-6 text-blue-500 flex-shrink-0 mt-1" />
            <div>
              <h2 className="text-lg font-semibold text-blue-200 mb-2">Important: Store Credit Only</h2>
              <p className="text-gray-300">
                Our trade-in program offers store credit only, which can be used exclusively for purchasing new mining equipment from Euronet Trading. We do not provide cash payments for trade-ins. This credit system allows us to offer better value and helps you upgrade to newer, more efficient mining hardware.
              </p>
            </div>
          </div>
        </div>

        <div className="grid lg:grid-cols-2 gap-12 mb-24">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
          >
            <h2 className="text-2xl font-bold mb-6">Why Trade In With Us?</h2>
            <div className="space-y-4">
              <div className="flex items-start space-x-4">
                <div className="bg-blue-500/10 p-3 rounded-lg">
                  <ArrowRight className="h-6 w-6 text-blue-500" />
                </div>
                <div>
                  <h3 className="font-semibold mb-1">Maximum Store Credit Value</h3>
                  <p className="text-gray-400">Get the best trade-in value as store credit towards new equipment</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="bg-blue-500/10 p-3 rounded-lg">
                  <ArrowRight className="h-6 w-6 text-blue-500" />
                </div>
                <div>
                  <h3 className="font-semibold mb-1">Seamless Upgrades</h3>
                  <p className="text-gray-400">Apply your credit instantly to new mining hardware</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="bg-blue-500/10 p-3 rounded-lg">
                  <ArrowRight className="h-6 w-6 text-blue-500" />
                </div>
                <div>
                  <h3 className="font-semibold mb-1">Secure Process</h3>
                  <p className="text-gray-400">Safe and verified equipment exchange system</p>
                </div>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4 }}
            className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-2xl p-8"
          >
            <h2 className="text-2xl font-bold mb-6">Accepted Hardware</h2>
            <div className="space-y-4">
              <div className="p-4 bg-black/40 rounded-lg">
                <h3 className="font-semibold mb-2">Antminer Series</h3>
                <p className="text-gray-400">S19, S19 Pro, S19j Pro, S19 XP, T19</p>
              </div>
              <div className="p-4 bg-black/40 rounded-lg">
                <h3 className="font-semibold mb-2">Whatsminer Series</h3>
                <p className="text-gray-400">M30S, M30S+, M30S++, M31S, M50</p>
              </div>
              <div className="p-4 bg-black/40 rounded-lg">
                <h3 className="font-semibold mb-2">Avalon Series</h3>
                <p className="text-gray-400">A1166, A1246, A1266</p>
              </div>
            </div>
          </motion.div>
        </div>

        <div className="mb-24">
          <h2 className="text-2xl font-bold text-center mb-12">How It Works</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {steps.map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 * index }}
                className="text-center"
              >
                <div className="bg-blue-500/10 p-4 rounded-full inline-block mb-4">
                  {step.icon}
                </div>
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-400">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>

        <TradeInCalculator />
      </motion.div>
    </div>
  );
};

export default TradeInPage;