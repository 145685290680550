import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { X, Plus, Server, AlertTriangle } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { hostingModels } from '../data/hostingModels';

interface HostingRequestFormProps {
  onSubmit: (data: any) => void;
  onClose: () => void;
}

const HostingRequestForm = ({ onSubmit, onClose }: HostingRequestFormProps) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    minerModel: '',
    quantity: 1,
    serialNumbers: [''],
    contractMonths: 6,
    shippingAddress: '',
    billingAddress: '',
    contactName: '',
    companyName: '',
    contactEmail: '',
    contactPhone: '',
    agreeToTerms: false
  });

  const calculateMonthlyPower = () => {
    if (!formData.minerModel) return '0';
    const powerConsumption = hostingModels[formData.minerModel].power;
    const kwhPerMonth = (powerConsumption * 24 * 30) / 1000;
    return (kwhPerMonth * 0.045 * formData.quantity).toFixed(2);
  };

  const calculateMonthlyInsurance = () => {
    return (35 * formData.quantity).toFixed(2);
  };

  const calculateTotalMonthly = () => {
    return (parseFloat(calculateMonthlyPower()) + parseFloat(calculateMonthlyInsurance())).toFixed(2);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const { data, error } = await supabase
        .from('hosting_requests')
        .insert([{
          miner_model: hostingModels[formData.minerModel].name,
          quantity: formData.quantity,
          serial_numbers: formData.serialNumbers.filter(s => s.trim()),
          contract_months: formData.contractMonths,
          monthly_power_cost: parseFloat(calculateMonthlyPower()),
          monthly_insurance: parseFloat(calculateMonthlyInsurance()),
          total_monthly_cost: parseFloat(calculateTotalMonthly()),
          shipping_address: formData.shippingAddress,
          billing_address: formData.billingAddress,
          contact_name: formData.contactName,
          company_name: formData.companyName || null,
          contact_email: formData.contactEmail,
          contact_phone: formData.contactPhone,
          status: 'pending',
          payment_status: 'pending'
        }])
        .select()
        .single();

      if (error) throw error;
      
      onSubmit(data);
    } catch (error) {
      console.error('Error submitting hosting request:', error);
      alert('Failed to submit hosting request. Please try again.');
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-bold mb-4">Miner Details</h3>
            
            <div>
              <label className="block text-sm font-medium mb-2">Select Miner Model</label>
              <select
                value={formData.minerModel}
                onChange={(e) => setFormData({ ...formData, minerModel: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              >
                <option value="">Choose a model</option>
                {Object.entries(hostingModels).map(([id, model]) => (
                  <option key={id} value={id}>{model.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Quantity</label>
              <input
                type="number"
                min="1"
                value={formData.quantity}
                onChange={(e) => setFormData({ ...formData, quantity: parseInt(e.target.value) || 1 })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Serial Numbers</label>
              {formData.serialNumbers.map((serial, index) => (
                <div key={index} className="flex gap-2 mb-2">
                  <input
                    type="text"
                    value={serial}
                    onChange={(e) => {
                      const newSerials = [...formData.serialNumbers];
                      newSerials[index] = e.target.value;
                      setFormData({ ...formData, serialNumbers: newSerials });
                    }}
                    className="flex-1 bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder={`Serial number ${index + 1}`}
                  />
                  {index === formData.serialNumbers.length - 1 && (
                    <button
                      type="button"
                      onClick={() => setFormData({
                        ...formData,
                        serialNumbers: [...formData.serialNumbers, '']
                      })}
                      className="p-2 bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors"
                    >
                      <Plus className="h-5 w-5" />
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Contract Duration (months)</label>
              <select
                value={formData.contractMonths}
                onChange={(e) => setFormData({ ...formData, contractMonths: parseInt(e.target.value) })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              >
                <option value="6">6 months</option>
                <option value="12">12 months</option>
                <option value="24">24 months</option>
              </select>
            </div>

            {formData.minerModel && (
              <div className="bg-black/40 rounded-lg p-4">
                <h4 className="font-semibold mb-2">Monthly Costs</h4>
                <div className="space-y-2">
                  <p><span className="text-gray-400">Power Cost:</span> €{calculateMonthlyPower()}</p>
                  <p><span className="text-gray-400">Insurance:</span> €{calculateMonthlyInsurance()}</p>
                  <p className="text-lg font-bold mt-2">Total Monthly: €{calculateTotalMonthly()}</p>
                </div>
              </div>
            )}

            <button
              type="button"
              onClick={() => setStep(2)}
              disabled={!formData.minerModel}
              className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Continue to Contact Details
            </button>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-bold mb-4">Contact Information</h3>

            <div>
              <label className="block text-sm font-medium mb-2">Full Name</label>
              <input
                type="text"
                value={formData.contactName}
                onChange={(e) => setFormData({ ...formData, contactName: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Company Name (Optional)</label>
              <input
                type="text"
                value={formData.companyName}
                onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Email</label>
              <input
                type="email"
                value={formData.contactEmail}
                onChange={(e) => setFormData({ ...formData, contactEmail: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Phone</label>
              <input
                type="tel"
                value={formData.contactPhone}
                onChange={(e) => setFormData({ ...formData, contactPhone: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <button
              type="button"
              onClick={() => setStep(3)}
              disabled={!formData.contactName || !formData.contactEmail || !formData.contactPhone}
              className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Continue to Shipping Details
            </button>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-bold mb-4">Shipping Information</h3>

            <div>
              <label className="block text-sm font-medium mb-2">Shipping Address</label>
              <textarea
                value={formData.shippingAddress}
                onChange={(e) => setFormData({ ...formData, shippingAddress: e.target.value })}
                rows={4}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Billing Address</label>
              <textarea
                value={formData.billingAddress}
                onChange={(e) => setFormData({ ...formData, billingAddress: e.target.value })}
                rows={4}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </div>

            <div className="bg-yellow-500/10 border border-yellow-500/50 rounded-lg p-4">
              <div className="flex items-start gap-3">
                <AlertTriangle className="h-5 w-5 text-yellow-500 flex-shrink-0 mt-1" />
                <div>
                  <h4 className="font-semibold text-yellow-500">Important Notice</h4>
                  <ul className="list-disc list-inside text-sm mt-2">
                    <li>First month's payment required before shipping</li>
                    <li>Shipping costs are your responsibility</li>
                    <li>Insurance coverage starts upon receipt</li>
                    <li>Minimum contract period applies</li>
                  </ul>
                </div>
              </div>
            </div>

            <label className="flex items-start gap-3">
              <input
                type="checkbox"
                checked={formData.agreeToTerms}
                onChange={(e) => setFormData({ ...formData, agreeToTerms: e.target.checked })}
                className="mt-1 rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                required
              />
              <span className="text-sm text-gray-400">
                I understand and agree to the hosting terms, payment requirements, and minimum contract period
              </span>
            </label>

            <button
              type="submit"
              onClick={handleSubmit}
              disabled={!formData.agreeToTerms || !formData.shippingAddress || !formData.billingAddress}
              className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
            >
              <Server className="h-5 w-5" />
              <span>Submit Hosting Request</span>
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-gradient-to-b from-blue-900/40 to-black/40 rounded-2xl max-w-2xl w-full max-h-[90vh] overflow-y-auto border border-white/10"
      >
        <div className="sticky top-0 bg-black/90 backdrop-blur-sm p-6 border-b border-white/10">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-2xl font-bold">New Hosting Request</h2>
              <div className="flex mt-2">
                {[1, 2, 3].map((stepNumber) => (
                  <div
                    key={stepNumber}
                    className={`w-16 h-1 rounded-full mr-2 ${
                      stepNumber <= step ? 'bg-blue-500' : 'bg-gray-700'
                    }`}
                  />
                ))}
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-white/10 rounded-lg transition-colors"
            >
              <X className="h-6 w-6" />
            </button>
          </div>
        </div>

        <form onSubmit={(e) => e.preventDefault()} className="p-6">
          {renderStep()}
        </form>
      </motion.div>
    </div>
  );
};

export default HostingRequestForm;