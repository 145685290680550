import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle2, Package, User, MapPin, Clock, FileText } from 'lucide-react';
import { formatPrice } from '../utils/priceFormatter';

interface HostingConfirmationProps {
  request: {
    miner_model: string;
    quantity: number;
    serial_numbers: string[];
    contract_months: number;
    monthly_power_cost: number;
    monthly_insurance: number;
    total_monthly_cost: number;
    shipping_address: string;
    contact_name: string;
    contact_email: string;
    contact_phone: string;
  };
  onClose: () => void;
}

const HostingConfirmation = ({ request, onClose }: HostingConfirmationProps) => {
  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-gradient-to-b from-blue-900/40 to-black/40 rounded-2xl max-w-2xl w-full max-h-[90vh] overflow-y-auto border border-white/10"
      >
        <div className="p-6">
          <div className="text-center mb-8">
            <div className="inline-flex items-center justify-center w-16 h-16 bg-green-500/20 rounded-full mb-4">
              <CheckCircle2 className="h-8 w-8 text-green-500" />
            </div>
            <h2 className="text-2xl font-bold mb-2">Hosting Request Submitted!</h2>
            <p className="text-gray-400">
              Your hosting request has been received and is being processed.
            </p>
          </div>

          <div className="space-y-6">
            <div className="bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl border border-white/10 p-6">
              <div className="flex items-center gap-3 mb-4">
                <Package className="h-5 w-5 text-blue-500" />
                <h3 className="text-lg font-semibold">Miner Details</h3>
              </div>
              
              <div className="space-y-2">
                <p><span className="text-gray-400">Model:</span> {request.miner_model}</p>
                <p><span className="text-gray-400">Quantity:</span> {request.quantity}</p>
                <p><span className="text-gray-400">Serial Numbers:</span></p>
                <ul className="list-disc list-inside text-gray-400">
                  {request.serial_numbers.map((serial, index) => (
                    <li key={index}>{serial}</li>
                  ))}
                </ul>
                <p><span className="text-gray-400">Contract Duration:</span> {request.contract_months} months</p>
              </div>
            </div>

            <div className="bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl border border-white/10 p-6">
              <div className="flex items-center gap-3 mb-4">
                <User className="h-5 w-5 text-blue-500" />
                <h3 className="text-lg font-semibold">Contact Information</h3>
              </div>
              
              <div className="space-y-2">
                <p><span className="text-gray-400">Name:</span> {request.contact_name}</p>
                <p><span className="text-gray-400">Email:</span> {request.contact_email}</p>
                <p><span className="text-gray-400">Phone:</span> {request.contact_phone}</p>
              </div>
            </div>

            <div className="bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl border border-white/10 p-6">
              <div className="flex items-center gap-3 mb-4">
                <MapPin className="h-5 w-5 text-blue-500" />
                <h3 className="text-lg font-semibold">Shipping Address</h3>
              </div>
              
              <p className="text-gray-400 whitespace-pre-wrap">{request.shipping_address}</p>
            </div>

            <div className="bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl border border-white/10 p-6">
              <div className="flex items-center gap-3 mb-4">
                <Clock className="h-5 w-5 text-blue-500" />
                <h3 className="text-lg font-semibold">Monthly Costs</h3>
              </div>
              
              <div className="space-y-2">
                <p><span className="text-gray-400">Power Cost:</span> {formatPrice(request.monthly_power_cost)}</p>
                <p><span className="text-gray-400">Insurance:</span> {formatPrice(request.monthly_insurance)}</p>
                <p className="text-xl font-bold mt-4">Total Monthly: {formatPrice(request.total_monthly_cost)}</p>
              </div>
            </div>

            <div className="bg-blue-500/10 border border-blue-500/50 rounded-lg p-4">
              <div className="flex items-start gap-3">
                <FileText className="h-5 w-5 text-blue-500 flex-shrink-0 mt-1" />
                <div>
                  <h4 className="font-semibold text-blue-200 mb-2">Next Steps</h4>
                  <ol className="list-decimal list-inside space-y-2 text-gray-300">
                    <li>Our team will review your request within 24 hours</li>
                    <li>You'll receive an email with payment instructions for the first month</li>
                    <li>Once payment is confirmed, we'll send shipping instructions</li>
                    <li>Ship your miner to our facility using the provided shipping label</li>
                    <li>We'll notify you when your miner arrives and is set up</li>
                  </ol>
                </div>
              </div>
            </div>

            <button
              onClick={onClose}
              className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all"
            >
              Close
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default HostingConfirmation;