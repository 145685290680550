import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Trash2, ArrowRight } from 'lucide-react';
import { useCartStore } from '../stores/cartStore';
import { formatPrice } from '../utils/priceFormatter';
import PaymentModal from '../components/PaymentModal';
import OrderConfirmation from '../components/OrderConfirmation';
import CheckoutForm from '../components/CheckoutForm';

const CartPage = () => {
  const [step, setStep] = useState(1);
  const [orderNumber, setOrderNumber] = useState('');
  const [paymentMethod, setPaymentMethod] = useState<'crypto' | 'bank' | null>(null);
  const [customerInfo, setCustomerInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    postalCode: '',
    notes: ''
  });

  const { 
    items, 
    removeItem, 
    updateQuantity, 
    updateShippingOption,
    getSubtotal,
    getShippingTotal,
    getDDPTotal,
    getTotal,
    clearCart 
  } = useCartStore();

  const handlePaymentComplete = (orderId: string) => {
    setOrderNumber(orderId);
    setStep(4);
    clearCart();
  };

  if (items.length === 0 && step === 1) {
    return (
      <div className="min-h-screen pt-24 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-3xl font-bold mb-4">Your Cart is Empty</h1>
            <p className="text-gray-400 mb-8">Add some products to your cart to continue shopping.</p>
            <a
              href="/products"
              className="inline-flex items-center bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-colors"
            >
              Browse Products
              <ArrowRight className="ml-2 h-5 w-5" />
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen pt-24 pb-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {step === 1 && (
          <div className="space-y-8">
            <h1 className="text-3xl font-bold">Shopping Cart</h1>
            
            <div className="grid lg:grid-cols-3 gap-8">
              <div className="lg:col-span-2 space-y-4">
                {items.map((item) => (
                  <div
                    key={item.id}
                    className="bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl border border-white/10 p-4"
                  >
                    <div className="flex gap-4">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="w-24 h-24 object-cover rounded-lg"
                      />
                      <div className="flex-grow">
                        <div className="flex justify-between">
                          <h3 className="font-semibold">{item.name}</h3>
                          <button
                            onClick={() => removeItem(item.id)}
                            className="text-red-500 hover:text-red-400"
                          >
                            <Trash2 className="h-5 w-5" />
                          </button>
                        </div>
                        <p className="text-gray-400">{formatPrice(item.price)}</p>
                        
                        <div className="mt-4 flex items-center gap-4">
                          <div className="flex items-center">
                            <button
                              onClick={() => updateQuantity(item.id, item.quantity - 1)}
                              className="px-3 py-1 bg-black/40 rounded-l-lg hover:bg-black/60"
                            >
                              -
                            </button>
                            <input
                              type="number"
                              min="1"
                              value={item.quantity}
                              onChange={(e) => updateQuantity(item.id, parseInt(e.target.value))}
                              className="w-16 px-3 py-1 bg-black/40 text-center"
                            />
                            <button
                              onClick={() => updateQuantity(item.id, item.quantity + 1)}
                              className="px-3 py-1 bg-black/40 rounded-r-lg hover:bg-black/60"
                            >
                              +
                            </button>
                          </div>
                          
                          <select
                            value={item.shippingOption}
                            onChange={(e) => updateShippingOption(item.id, e.target.value as 'standard' | 'ddp')}
                            className="bg-black/40 border border-white/10 rounded-lg px-3 py-1"
                          >
                            <option value="standard">Standard Shipping</option>
                            <option value="ddp">DDP Shipping</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              
              <div className="bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl border border-white/10 p-6 h-fit">
                <h3 className="text-xl font-bold mb-4">Order Summary</h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span>Subtotal</span>
                    <span>{formatPrice(getSubtotal())}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Shipping</span>
                    <span>{formatPrice(getShippingTotal())}</span>
                  </div>
                  {getDDPTotal() > 0 && (
                    <div className="flex justify-between">
                      <span>DDP Fee (18%)</span>
                      <span>{formatPrice(getDDPTotal())}</span>
                    </div>
                  )}
                  <div className="border-t border-white/10 pt-2 mt-2">
                    <div className="flex justify-between font-bold">
                      <span>Total</span>
                      <span>{formatPrice(getTotal())}</span>
                    </div>
                  </div>
                </div>
                
                <button
                  onClick={() => setStep(2)}
                  className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-colors mt-6 flex items-center justify-center"
                >
                  Proceed to Checkout
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <CheckoutForm
            onBack={() => setStep(1)}
            onSubmit={(data) => {
              setCustomerInfo(data);
              setStep(3);
            }}
            initialData={customerInfo}
          />
        )}

        {step === 3 && (
          <div className="max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold mb-8">Select Payment Method</h2>
            <div className="grid md:grid-cols-2 gap-6">
              <button
                onClick={() => setPaymentMethod('crypto')}
                className="bg-gradient-to-b from-blue-900/20 to-transparent p-6 rounded-xl border border-white/10 hover:border-blue-500/50 transition-all text-center"
              >
                <h3 className="text-xl font-bold mb-2">Cryptocurrency</h3>
                <p className="text-gray-400">Pay with Bitcoin, Ethereum, or USDT</p>
              </button>
              
              <button
                onClick={() => setPaymentMethod('bank')}
                className="bg-gradient-to-b from-blue-900/20 to-transparent p-6 rounded-xl border border-white/10 hover:border-blue-500/50 transition-all text-center"
              >
                <h3 className="text-xl font-bold mb-2">Bank Transfer</h3>
                <p className="text-gray-400">Pay via SEPA or international wire transfer</p>
              </button>
            </div>
          </div>
        )}

        {paymentMethod && (
          <PaymentModal
            paymentMethod={paymentMethod}
            amount={getTotal()}
            onClose={() => setPaymentMethod(null)}
            onComplete={handlePaymentComplete}
            customerInfo={customerInfo}
            items={items}
          />
        )}

        {step === 4 && orderNumber && (
          <OrderConfirmation
            orderNumber={orderNumber}
            customerInfo={customerInfo}
            items={items}
            total={getTotal()}
          />
        )}
      </div>
    </div>
  );
};

export default CartPage;