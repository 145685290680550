import { useQuery } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';

export const useAdmin = () => {
  return useQuery({
    queryKey: ['admin-status'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return false;

      const { data, error } = await supabase
        .rpc('is_admin', { user_id: user.id });

      if (error) {
        console.error('Error checking admin status:', error);
        return false;
      }

      return !!data;
    }
  });
};