export const products = [
  // LTC+Doge+Bel Miners
  {
    id: 1,
    name: "L9 16G",
    image: "https://images.unsplash.com/photo-1640079421264-61f9d6f3efb1?auto=format&fit=crop&q=80&w=600&h=400",
    price: 13500,
    hashrate: "16 GH/s",
    algorithm: "Scrypt",
    category: "LTC+Doge+Bel Miner",
    stock: "This week",
    powerConsumption: "3200W",
    cooling: "Air cooling",
    warranty: "180-day manufacturer warranty"
  },
  // ... Add all other products here
  // Copy the products array from the previous code
];