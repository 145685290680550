import React from 'react';
import { motion } from 'framer-motion';
import { Users, Building2, Trophy, Globe2 } from 'lucide-react';

const AboutPage = () => {
  return (
    <div className="pt-24 pb-16">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">About Euronet Trading</h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Leading the revolution in cryptocurrency mining hardware trading since 2018
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-12 mb-24">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
          >
            <h2 className="text-2xl font-bold mb-4">Our Story</h2>
            <p className="text-gray-400 mb-4">
              Founded by a team of cryptocurrency enthusiasts and hardware experts, 
              Euronet Trading emerged from the need for a reliable and transparent 
              platform for trading mining equipment.
            </p>
            <p className="text-gray-400">
              Today, we're proud to be Europe's leading platform for cryptocurrency 
              mining hardware trading, serving thousands of satisfied customers across 
              the continent.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4 }}
            className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-2xl p-8"
          >
            <h2 className="text-2xl font-bold mb-4">Company Stats</h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="text-center p-4">
                <div className="text-3xl font-bold text-blue-500">5000+</div>
                <div className="text-gray-400">Trades Completed</div>
              </div>
              <div className="text-center p-4">
                <div className="text-3xl font-bold text-blue-500">98%</div>
                <div className="text-gray-400">Customer Satisfaction</div>
              </div>
              <div className="text-center p-4">
                <div className="text-3xl font-bold text-blue-500">25+</div>
                <div className="text-gray-400">Countries Served</div>
              </div>
              <div className="text-center p-4">
                <div className="text-3xl font-bold text-blue-500">€50M+</div>
                <div className="text-gray-400">Trading Volume</div>
              </div>
            </div>
          </motion.div>
        </div>

        <div className="grid md:grid-cols-4 gap-8 mb-24">
          {[
            { icon: <Users className="h-8 w-8" />, title: "Expert Team" },
            { icon: <Building2 className="h-8 w-8" />, title: "Global Presence" },
            { icon: <Trophy className="h-8 w-8" />, title: "Industry Leader" },
            { icon: <Globe2 className="h-8 w-8" />, title: "24/7 Support" }
          ].map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 * index }}
              className="text-center p-6 bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl border border-white/10"
            >
              <div className="bg-blue-500/10 p-4 rounded-full inline-block mb-4">
                {item.icon}
              </div>
              <h3 className="text-xl font-semibold">{item.title}</h3>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
          className="text-center"
        >
          <h2 className="text-2xl font-bold mb-8">Our Mission</h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            To revolutionize the cryptocurrency mining hardware market by providing 
            a secure, efficient, and transparent trading platform that empowers miners 
            to upgrade their operations with confidence.
          </p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default AboutPage;