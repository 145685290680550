import React, { useState } from 'react';
import { Search, UserPlus, Shield, Trash2, Mail, Phone, Building2, MapPin, Save, X } from 'lucide-react';
import { useUsers } from '../../hooks/useUsers';
import { User, UserFormData } from '../../data/types';

const UsersManagement = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const { users, updateUser, deleteUser } = useUsers();

  const handleUpdateUser = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editingUser) return;

    try {
      await updateUser.mutateAsync({
        id: editingUser.id,
        data: {
          full_name: editingUser.full_name,
          phone: editingUser.phone,
          company: editingUser.company,
          billing_address: editingUser.billing_address,
          shipping_address: editingUser.shipping_address,
          is_admin: editingUser.is_admin
        }
      });
      setEditingUser(null);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleDeleteUser = async (user: User) => {
    if (!window.confirm(`Are you sure you want to delete ${user.email}?`)) {
      return;
    }

    try {
      await deleteUser.mutateAsync(user.id);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const filteredUsers = users.data?.filter(user =>
    user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.full_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.company?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (users.isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      <div className="flex flex-wrap gap-4">
        <div className="flex-1">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search users..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full bg-black/50 border border-white/10 rounded-lg pl-10 pr-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-xl overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b border-white/10">
                <th className="text-left p-4">User</th>
                <th className="text-left p-4">Contact</th>
                <th className="text-left p-4">Role</th>
                <th className="text-left p-4">Joined</th>
                <th className="text-left p-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers?.map((user) => (
                <tr key={user.id} className="border-b border-white/10">
                  <td className="p-4">
                    <div className="flex items-center gap-3">
                      <div className="w-10 h-10 rounded-full bg-blue-500/20 flex items-center justify-center">
                        <span className="text-lg font-semibold">
                          {user.full_name?.[0] || user.email[0].toUpperCase()}
                        </span>
                      </div>
                      <div>
                        <p className="font-semibold">{user.full_name || 'No name'}</p>
                        <p className="text-sm text-gray-400">{user.email}</p>
                      </div>
                    </div>
                  </td>
                  <td className="p-4">
                    <div className="space-y-1">
                      {user.phone && (
                        <div className="flex items-center text-gray-400">
                          <Phone className="h-4 w-4 mr-2" />
                          {user.phone}
                        </div>
                      )}
                      {user.company && (
                        <div className="flex items-center text-gray-400">
                          <Building2 className="h-4 w-4 mr-2" />
                          {user.company}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="p-4">
                    <button
                      onClick={() => {
                        if (editingUser?.id === user.id) {
                          setEditingUser({
                            ...editingUser,
                            is_admin: !editingUser.is_admin
                          });
                        } else {
                          setEditingUser({
                            ...user,
                            is_admin: !user.is_admin
                          });
                        }
                      }}
                      className={`px-3 py-1 rounded-full text-sm flex items-center gap-2 ${
                        user.is_admin
                          ? 'bg-blue-500/20 text-blue-400'
                          : 'bg-gray-500/20 text-gray-400'
                      }`}
                    >
                      <Shield className="h-4 w-4" />
                      {user.is_admin ? 'Admin' : 'User'}
                    </button>
                  </td>
                  <td className="p-4">
                    <span className="text-sm text-gray-400">
                      {new Date(user.created_at).toLocaleDateString()}
                    </span>
                  </td>
                  <td className="p-4">
                    <div className="flex gap-2">
                      {editingUser?.id === user.id ? (
                        <>
                          <button
                            onClick={handleUpdateUser}
                            className="p-2 hover:bg-green-500/20 rounded-lg transition-colors text-green-500"
                            title="Save changes"
                          >
                            <Save className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => setEditingUser(null)}
                            className="p-2 hover:bg-red-500/20 rounded-lg transition-colors text-red-500"
                            title="Cancel"
                          >
                            <X className="h-5 w-5" />
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={() => handleDeleteUser(user)}
                          className="p-2 hover:bg-red-500/20 rounded-lg transition-colors text-red-500"
                          title="Delete user"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UsersManagement;