import React, { useState } from 'react';
import { Plus, Pencil, Trash2, Search, Filter } from 'lucide-react';
import { useProducts } from '../../hooks/useProducts';
import { useAdminProducts } from '../../hooks/useAdminProducts';
import { useCategories } from '../../hooks/useCategories';
import ProductForm from '../../components/admin/ProductForm';
import CategoryForm from '../../components/admin/CategoryForm';
import { Product } from '../../data/types';
import { formatPrice } from '../../utils/priceFormatter';

const ProductsManagement = () => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [showProductForm, setShowProductForm] = useState(false);
  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(undefined);

  const { data: products, isLoading } = useProducts(selectedCategory);
  const { addProduct, updateProduct, deleteProduct } = useAdminProducts();
  const { categories, addCategory } = useCategories();

  const handleProductSubmit = async (formData: FormData) => {
    try {
      if (selectedProduct) {
        await updateProduct.mutateAsync({
          id: selectedProduct.id,
          ...Object.fromEntries(formData)
        });
      } else {
        await addProduct.mutateAsync(Object.fromEntries(formData));
      }
      setShowProductForm(false);
      setSelectedProduct(null);
    } catch (error) {
      console.error('Error saving product:', error);
      alert('Failed to save product. Please try again.');
    }
  };

  const handleDeleteProduct = async (product: Product) => {
    if (!window.confirm(`Are you sure you want to delete ${product.name}?`)) {
      return;
    }

    try {
      await deleteProduct.mutateAsync(product.id);
    } catch (error) {
      console.error('Error deleting product:', error);
      alert('Failed to delete product. Please try again.');
    }
  };

  if (isLoading || categories.isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      <div className="flex flex-wrap gap-4">
        <div className="flex-1">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search products..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full bg-black/50 border border-white/10 rounded-lg pl-10 pr-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>
        
        <div className="flex gap-4">
          <button
            onClick={() => setShowCategoryForm(true)}
            className="bg-black/50 border border-white/10 px-4 py-2 rounded-lg hover:bg-white/10 transition-colors"
          >
            <Filter className="h-5 w-5" />
          </button>

          <button
            onClick={() => setShowProductForm(true)}
            className="bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg transition-colors flex items-center gap-2"
          >
            <Plus className="h-5 w-5" />
            Add Product
          </button>
        </div>
      </div>

      <div className="flex flex-wrap gap-4 mb-6">
        <button
          onClick={() => setSelectedCategory(undefined)}
          className={`px-4 py-2 rounded-lg transition-colors ${
            !selectedCategory
              ? 'bg-blue-600'
              : 'bg-black/40 hover:bg-blue-600/20'
          }`}
        >
          All Products
        </button>
        {categories.data?.map((category) => (
          <button
            key={category.id}
            onClick={() => setSelectedCategory(category.id)}
            className={`px-4 py-2 rounded-lg transition-colors ${
              selectedCategory === category.id
                ? 'bg-blue-600'
                : 'bg-black/40 hover:bg-blue-600/20'
            }`}
          >
            {category.name}
          </button>
        ))}
      </div>

      <div className="grid gap-6">
        {products?.map((product) => (
          <div
            key={product.id}
            className="bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl border border-white/10 p-6"
          >
            <div className="flex gap-6">
              <img
                src={product.image}
                alt={product.name}
                className="w-32 h-32 object-cover rounded-lg"
              />
              <div className="flex-grow">
                <div className="flex justify-between mb-4">
                  <div>
                    <h3 className="text-lg font-semibold">{product.name}</h3>
                    <p className="text-gray-400">{product.hashrate} • {product.algorithm}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => {
                        setSelectedProduct(product);
                        setShowProductForm(true);
                      }}
                      className="p-2 hover:bg-white/10 rounded-lg transition-colors text-blue-500"
                    >
                      <Pencil className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDeleteProduct(product)}
                      className="p-2 hover:bg-white/10 rounded-lg transition-colors text-red-500"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                <div className="grid md:grid-cols-3 gap-4">
                  <div>
                    <span className="text-gray-400">Category:</span>
                    <span className="ml-2">{product.category}</span>
                  </div>
                  <div>
                    <span className="text-gray-400">Stock:</span>
                    <span className="ml-2">{product.stock || 'Out of stock'}</span>
                  </div>
                  <div>
                    <span className="text-gray-400">Price:</span>
                    <span className="ml-2 font-bold text-blue-500">
                      {formatPrice(product.price)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showProductForm && (
        <ProductForm
          product={selectedProduct || undefined}
          onSubmit={handleProductSubmit}
          onClose={() => {
            setShowProductForm(false);
            setSelectedProduct(null);
          }}
        />
      )}

      {showCategoryForm && (
        <CategoryForm
          onClose={() => setShowCategoryForm(false)}
          onSubmit={async (name) => {
            try {
              if (addCategory) {
                await addCategory.mutateAsync(name);
                setShowCategoryForm(false);
              }
            } catch (error) {
              console.error('Error adding category:', error);
              alert('Failed to add category. Please try again.');
            }
          }}
        />
      )}
    </div>
  );
};

export default ProductsManagement;